var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import { BoltIcon, MapPinIcon, ChevronLeftIcon, ChevronRightIcon, ChevronDownIcon, ChevronUpIcon, ArrowRightOnRectangleIcon, PencilIcon, UsersIcon, } from "@heroicons/react/24/outline";
import { useAuth0 } from "@auth0/auth0-react";
import { Dialog } from "@headlessui/react";
import { process } from "../Process";
import { CopyBlock, dracula } from "react-code-blocks";
import { StyledButton, StyledButtonStyle } from "./StyledButton";
var FeedbackState;
(function (FeedbackState) {
    FeedbackState[FeedbackState["Input"] = 0] = "Input";
    FeedbackState[FeedbackState["Sending"] = 1] = "Sending";
    FeedbackState[FeedbackState["Success"] = 2] = "Success";
    FeedbackState[FeedbackState["Fail"] = 3] = "Fail";
})(FeedbackState || (FeedbackState = {}));
var Feedback = function (_a) {
    var close = _a.close;
    var _b = useState(FeedbackState.Input), feedbackState = _b[0], setFeedbackState = _b[1];
    var getAccessTokenSilently = useAuth0().getAccessTokenSilently;
    var feedbackRef = useRef(null);
    var onSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var feedback, accessToken, res;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    feedback = (_a = feedbackRef.current) === null || _a === void 0 ? void 0 : _a.value;
                    if (!feedback) {
                        return [2 /*return*/];
                    }
                    console.log(feedback);
                    setFeedbackState(FeedbackState.Sending);
                    return [4 /*yield*/, getAccessTokenSilently({
                            authorizationParams: {
                                audience: process.env.AUTH0_AUDIENCE,
                            },
                        })];
                case 1:
                    accessToken = _b.sent();
                    return [4 /*yield*/, fetch("".concat(process.env.CORE_SVC_URL, "/feedback/submit"), {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                authorization: "Bearer ".concat(accessToken),
                            },
                            body: JSON.stringify({ feedback: feedback }),
                        })];
                case 2:
                    res = _b.sent();
                    if (!res.ok) {
                        console.log("Error submitting feedback: ", res.statusText);
                        setFeedbackState(FeedbackState.Fail);
                    }
                    else {
                        setFeedbackState(FeedbackState.Success);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement("div", { className: "bg-black-1150 rounded-[20px] p-[32px] flex flex-col gap-y-[32px] items-center" },
        feedbackState == FeedbackState.Input && (React.createElement(React.Fragment, null,
            React.createElement("label", { className: "font-N5 text-white" }, "Give Us Your Feedback"),
            React.createElement("textarea", { ref: feedbackRef, rows: 4, className: "p-[16px] min-w-[450px] w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500", placeholder: "Write your thoughts here..." }),
            React.createElement("button", { className: "text-black-1200 font-N8 px-[24px] py-[16px] bg-black-200 hover:bg-black-400 rounded-[10px] cursor-pointer", onClick: onSubmit }, "SEND"))),
        feedbackState == FeedbackState.Success && (React.createElement(React.Fragment, null,
            React.createElement("label", { className: "font-N5 text-white" }, "Feedback Submitted"),
            React.createElement("label", { className: "font-N7 text-white max-w-[500px] text-center" }, "Thank you for providing feedback. A team member will review your feedback shortly. If you need immediate assistance, please reach out to customer support."),
            React.createElement("button", { className: "text-black-1200 font-N8 px-[24px] py-[16px] bg-black-200 hover:bg-black-400 rounded-[10px] cursor-pointer", onClick: close }, "CLOSE"))),
        feedbackState == FeedbackState.Sending && (React.createElement(React.Fragment, null,
            React.createElement("label", { className: "font-N7 text-white" }, "Please wait while your feedback is submitted."))),
        feedbackState == FeedbackState.Fail && (React.createElement(React.Fragment, null,
            React.createElement("label", { className: "font-N5 text-white" }, "Feedback"),
            React.createElement("label", { className: "font-N7 text-white" }, "Something went wrong when sending your feedback. Please try again or reach out to customer support."),
            React.createElement("button", { className: "text-black-1200 font-N8 px-[24px] py-[16px] bg-black-200 hover:bg-black-400 rounded-[10px] cursor-pointer", onClick: close }, "CLOSE")))));
};
var APIAccess = function (_a) {
    var close = _a.close, organizationId = _a.organizationId;
    var user = useAuth0().user;
    var curlCommand = [
        "curl --request POST",
        "           --url 'https://".concat(process.env.AUTH0_DOMAIN, "/oauth/token'"),
        "           --header 'content-type: application/x-www-form-urlencoded'",
        "           --data grant_type=password",
        "           --data 'username=".concat(user === null || user === void 0 ? void 0 : user.email, "'"),
        "           --data 'password=YOUR_PASSWORD'",
        "           --data 'client_id=".concat(process.env.AUTH0_CLIENTID, "'"),
        "           --data 'audience=".concat(process.env.AUTH0_AUDIENCE, "'"),
    ].join("  \\\n");
    var curlAPICommand = [
        "curl --request GET",
        "           --url '".concat(process.env.CORE_SVC_URL, "/site/list/").concat(organizationId, "'"),
        "           --header 'authorization: Bearer ACCESS_TOKEN'",
        "           --header 'content-type: application/json'",
    ].join("  \\\n");
    return (React.createElement("div", { className: "bg-black-1150 rounded-[20px] p-[32px] flex flex-col gap-y-[32px] items-center" },
        React.createElement("label", { className: "font-N5 text-white" }, "API Access"),
        React.createElement("label", { className: "font-N7 text-white mt-8" }, "To generate a secure token:"),
        React.createElement(CopyBlock, { text: curlCommand, showLineNumbers: true, language: "shell", theme: dracula }),
        React.createElement("label", { className: "font-N7 text-white" }, "To call an API with this token:"),
        React.createElement(CopyBlock, { text: curlAPICommand, showLineNumbers: true, language: "shell", theme: dracula }),
        React.createElement("a", { className: "font-N7 text-black-400 hover:text-white text-clip whitespace-nowrap", rel: "noopener noreferrer", href: "".concat(process.env.CORE_SVC_URL, "/documentation"), target: "_blank" }, "API DOCUMENTATION"),
        React.createElement(StyledButton, { style: StyledButtonStyle.Gray, text: "CLOSE", onClick: close, className: "mt-8" })));
};
var CustomerSupport = function (_a) {
    var close = _a.close;
    var supportEmail = "support@electriceratechnologies.com";
    return (React.createElement("div", { className: "bg-black-1150 rounded-[20px] p-[32px] flex flex-col gap-y-[32px] items-center" },
        React.createElement("label", { className: "font-N5 text-white" }, "Customer Support"),
        React.createElement("label", { className: "font-N7 text-white" }, "For customer support, please reach out to us at:"),
        React.createElement("div", { className: "flex flex-col gap-y-[8px]" },
            React.createElement("div", { className: "flex flex-row gap-x-[8px]" },
                React.createElement("label", { className: "font-N8 text-black-400" }, "Phone: "),
                React.createElement("label", { className: "font-N8 text-white" }, "+1 (507) 702 0312")),
            React.createElement("div", { className: "flex flex-row gap-x-[8px]" },
                React.createElement("label", { className: "font-N8 text-black-400" }, "Email: "),
                React.createElement("a", { href: "mailto:".concat(supportEmail), className: "font-N8 text-white hover:text-black-400" }, supportEmail))),
        React.createElement("button", { className: "text-black-1200 font-N8 px-[24px] py-[16px] bg-black-200 hover:bg-black-400 rounded-[10px] cursor-pointer", onClick: close }, "CLOSE")));
};
var SideNavItem = function (_a) {
    var getIcon = _a.getIcon, text = _a.text, link = _a.link, expanded = _a.expanded;
    return (React.createElement("div", null,
        React.createElement(NavLink, { to: link, className: function (_a) {
                var isActive = _a.isActive;
                return (isActive ? "text-white" : "text-black-600 hover:text-black-600") +
                    " flex flex-row items-center font-N7 hover:bg-black-1200 rounded-[14px] p-[8px] ml-[8px] ".concat(expanded ? "w-full" : "w-fit");
            } }, function (_a) {
            var isActive = _a.isActive;
            return (React.createElement(React.Fragment, null,
                getIcon(isActive),
                React.createElement("label", { className: "".concat(expanded ? "opacity-100" : "opacity-0", " translate-x-[16px] transition-opacity cursor-pointer text-clip whitespace-nowrap w-[0px] overflow-visible") }, text)));
        })));
};
export var SideNav = function (_a) {
    var customizationEnabled = _a.customizationEnabled, hasAdminAccess = _a.hasAdminAccess, organizationId = _a.organizationId;
    var _b = useAuth0(), user = _b.user, logout = _b.logout;
    var _c = useState(true), expanded = _c[0], setExpanded = _c[1];
    var iconSize = "w-[28px] h-[28px]";
    var iconStyle = "bg-black-800 text-black-600 hover:text-white hover:bg-black-1000 rounded-[50%] ".concat(iconSize, " p-[6px]");
    var onClick = function () {
        setExpanded(!expanded);
    };
    var onLogoutClick = function () {
        logout({ logoutParams: { returnTo: window.location.origin } });
    };
    var _d = useState(false), logoutExpanded = _d[0], setLogoutExpanded = _d[1];
    var toggleLogout = function () {
        setLogoutExpanded(!logoutExpanded);
    };
    var _e = useState(false), isFeedbackOpen = _e[0], setIsFeedbackOpen = _e[1];
    var _f = useState(false), isCustomerSupportOpen = _f[0], setIsCustomerSupportOpen = _f[1];
    var _g = useState(false), isAPIAccessOpen = _g[0], setIsAPIAccessOpen = _g[1];
    return (React.createElement("div", { className: "z-20 h-fit sticky top-0" },
        React.createElement("div", { className: "flex flex-row m-[8px] bg-black-1150 rounded-[20px] h-[700px] items-center" },
            React.createElement("div", { className: "flex flex-col gap-y-[4px] p-[16px] h-full transition-max-width ".concat(expanded ? "mr-[24px] max-w-[900px]" : "max-w-[100px]") },
                React.createElement("div", { className: "relative top-[6px] left-[6px] z-50" }, expanded && React.createElement("img", { src: "ee-logo.png", className: "h-8" })),
                React.createElement("div", { className: "flex flex-row items-center pl-[12px] pt-[12px] cursor-pointer", onClick: toggleLogout },
                    (user === null || user === void 0 ? void 0 : user.picture) && (React.createElement("img", { src: user === null || user === void 0 ? void 0 : user.picture, className: "h-[42px] w-[42px] rounded-[50%] grow-0" })),
                    React.createElement("div", { className: "flex flex-col gap-y-[4px] grow overflow-auto transition-opacity max-w-[150px] translate-x-[16px] ".concat(expanded ? "opacity-100" : "opacity-0") },
                        React.createElement("label", { className: "font-N7 text-black-400 overflow-hidden whitespace-nowrap text-ellipsis cursor-pointer" }, user === null || user === void 0 ? void 0 : user.name),
                        React.createElement("label", { className: "font-N9 text-black-600 overflow-hidden whitespace-nowrap text-ellipsis cursor-pointer" }, user === null || user === void 0 ? void 0 : user.email))),
                !logoutExpanded && (React.createElement(ChevronDownIcon, { className: "self-center h-[24px] w-[24px] text-black-600 hover:text-white cursor-pointer", onClick: function () {
                        setLogoutExpanded(true);
                    } })),
                logoutExpanded && (React.createElement(ChevronUpIcon, { className: "self-center h-[24px] w-[24px] text-black-600 hover:text-white cursor-pointer", onClick: function () {
                        setLogoutExpanded(false);
                    } })),
                React.createElement("div", { className: "self-center grid transition-opacity ".concat(logoutExpanded ? "opacity-100" : "opacity-0") },
                    React.createElement("div", { onClick: !expanded && logoutExpanded ? onLogoutClick : undefined, className: "p-[12px] overlap-grid transition-opacity bg-black-1200 hover:bg-black-800 rounded-[50%] mx-auto ".concat(expanded ? "opacity-0" : "opacity-100", " ").concat(!expanded && logoutExpanded ? "cursor-pointer z-10" : "") },
                        React.createElement(ArrowRightOnRectangleIcon, { className: "text-white h-[16px] w-[16px]" })),
                    React.createElement("div", { onClick: expanded && logoutExpanded ? onLogoutClick : undefined, className: "px-[16px] py-[8px] bg-black-1200 hover:bg-black-800 rounded-[10px] overlap-grid flex flex-row gap-x-[64px] transition-opacity items-center ".concat(!expanded ? "opacity-0" : "opacity-100", " ").concat(expanded && logoutExpanded ? "cursor-pointer z-10" : "") },
                        React.createElement("label", { className: "text-white font-N8p5 ".concat(expanded && logoutExpanded ? "cursor-pointer" : "") }, "Logout"),
                        React.createElement(ArrowRightOnRectangleIcon, { className: "h-[16px] w-[16px] text-white ".concat(expanded && logoutExpanded ? "cursor-pointer" : "") }))),
                React.createElement(SideNavItem, { getIcon: function () { return React.createElement(BoltIcon, { className: iconSize }); }, text: "Dashboard", link: "/", expanded: expanded }),
                React.createElement(SideNavItem, { getIcon: function () { return React.createElement(MapPinIcon, { className: iconSize }); }, text: "Stations", link: "stations", expanded: expanded }),
                customizationEnabled && (React.createElement(SideNavItem, { getIcon: function () { return React.createElement(PencilIcon, { className: iconSize }); }, text: "Customize", link: "customize", expanded: expanded })),
                hasAdminAccess && (React.createElement(SideNavItem, { getIcon: function () { return React.createElement(UsersIcon, { className: iconSize }); }, text: "Manage Users", link: "users", expanded: expanded })),
                React.createElement(ChevronRightIcon, { className: "".concat(iconStyle, " ").concat(!expanded ? "opacity-100 cursor-pointer" : "opacity-0", " self-center transition-opacity absolute top-[50%]"), onClick: !expanded ? onClick : undefined }),
                React.createElement("div", { className: "grow" }),
                React.createElement("div", { className: "w-fit flex flex-col transition-opacity gap-y-[8px] p-[16px] ".concat(expanded ? "opacity-100" : "opacity-0") },
                    React.createElement("a", { className: "text-black-600 hover:text-white text-clip whitespace-nowrap cursor-pointer", onClick: function () {
                            setIsAPIAccessOpen(true);
                        } }, "API Access"),
                    React.createElement(Dialog, { open: isAPIAccessOpen, onClose: function () { return setIsAPIAccessOpen(false); }, className: "relative z-50" },
                        React.createElement("div", { className: "fixed inset-0 bg-black/30", "aria-hidden": "true" }),
                        React.createElement("div", { className: "fixed inset-0 flex items-center justify-center p-4" },
                            React.createElement(Dialog.Panel, { className: "mx-auto" },
                                React.createElement(APIAccess, { close: function () { return setIsAPIAccessOpen(false); }, organizationId: organizationId })))),
                    React.createElement("a", { className: "text-black-600 hover:text-white text-clip whitespace-nowrap cursor-pointer", onClick: function () {
                            setIsCustomerSupportOpen(true);
                        } }, "Customer Support"),
                    React.createElement(Dialog, { open: isCustomerSupportOpen, onClose: function () { return setIsCustomerSupportOpen(false); }, className: "relative z-50" },
                        React.createElement("div", { className: "fixed inset-0 bg-black/30", "aria-hidden": "true" }),
                        React.createElement("div", { className: "fixed inset-0 flex items-center justify-center p-4" },
                            React.createElement(Dialog.Panel, { className: "mx-auto" },
                                React.createElement(CustomerSupport, { close: function () { return setIsCustomerSupportOpen(false); } })))),
                    React.createElement("a", { className: "text-black-600 hover:text-white text-clip whitespace-nowrap cursor-pointer", onClick: function () {
                            setIsFeedbackOpen(true);
                        } }, "Provide Feedback"),
                    React.createElement(Dialog, { open: isFeedbackOpen, onClose: function () { return setIsFeedbackOpen(false); }, className: "relative z-50" },
                        React.createElement("div", { className: "fixed inset-0 bg-black/30", "aria-hidden": "true" }),
                        React.createElement("div", { className: "fixed inset-0 flex items-center justify-center p-4" },
                            React.createElement(Dialog.Panel, { className: "mx-auto" },
                                React.createElement(Feedback, { close: function () { return setIsFeedbackOpen(false); } })))),
                    React.createElement("a", { className: "text-black-600 hover:text-white text-clip whitespace-nowrap", rel: "noopener noreferrer", href: "https://eestaticstore1.blob.core.windows.net/docs/PowerNode_Command_Console_Privacy_Policy_2023-02-15.pdf", target: "_blank" }, "Privacy Policy"))),
            React.createElement("div", { className: "w-[0px] overflow-visible" },
                React.createElement(ChevronLeftIcon, { className: "".concat(iconStyle, " ").concat(expanded ? "opacity-100 cursor-pointer" : "opacity-0", " transition-opacity translate-x-[-50%] translate-y-[16px]"), onClick: expanded ? onClick : undefined })))));
};
