var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useRef, useState, useCallback, useEffect, useMemo, } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import useSWR from "swr";
import { process } from "../../Process";
import { Auth0Fetcher } from "../../Auth0Fetcher";
import { useReactTable, getCoreRowModel, flexRender, } from "@tanstack/react-table";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { Disclosure } from "@headlessui/react";
import { SuccessIndicator } from "../SuccessIndicator";
import { events_svc } from "ee-proto-interfaces";
import { v4 as uuidv4 } from "uuid";
import { StyledButton, StyledButtonStyle } from "../StyledButton";
import { Dropdown } from "../Dropdown";
import { NOCControl, NOCCreate, } from "./NocControls";
var TableButton = function (_a) {
    var text = _a.text, onClick = _a.onClick, disabled = _a.disabled;
    return (React.createElement("button", { className: "h-12 w-12 rounded border p-1 hover:bg-gray-600 hover:shadow-lg hover:shadow-blue-500/30  disabled:cursor-not-allowed disabled:opacity-50", onClick: onClick, disabled: disabled }, text));
};
var CommandHistoryTable = function (_a) {
    var pnid = _a.pnid;
    var _b = React.useState({
        pageIndex: 0,
        pageSize: 10,
    }), _c = _b[0], pageIndex = _c.pageIndex, pageSize = _c.pageSize, setPagination = _b[1];
    var _d = useAuth0(), isAuthenticated = _d.isAuthenticated, isLoading = _d.isLoading, getAccessTokenSilently = _d.getAccessTokenSilently;
    var data = useSWR(isLoading || !isAuthenticated
        ? null
        : [
            "".concat(process.env.NOC_SVC_URL, "/powernodes/commands/").concat(pnid, "?take=").concat(pageSize, "&skip=").concat(pageSize * pageIndex),
            getAccessTokenSilently,
        ], Auth0Fetcher).data;
    var columns = React.useMemo(function () { return [
        {
            accessorKey: "commandId",
            header: "Command ID",
        },
        {
            accessorFn: function (row) { return new Date(row.createdDate).toLocaleString(); },
            header: "Created Date",
        },
        {
            accessorFn: function (row) { return new Date(row.updatedDate).toLocaleString(); },
            header: "Updated Date",
        },
        {
            accessorFn: function (row) {
                return row.commandContent.length == 1 &&
                    row.commandContent[0].legacyCommand
                    ? row.commandContent[0].legacyCommand.command
                    : JSON.stringify(row.commandContent);
            },
            header: "Command",
        },
        {
            accessorKey: "latestStatus",
            header: "Status",
        },
        {
            accessorKey: "username",
            header: "Username",
        },
    ]; }, []);
    var pagination = React.useMemo(function () { return ({
        pageIndex: pageIndex,
        pageSize: pageSize,
    }); }, [pageIndex, pageSize]);
    var table = useReactTable({
        data: data ? data.commands : [],
        columns: columns,
        pageCount: data ? data.pages : -1,
        manualPagination: true,
        getCoreRowModel: getCoreRowModel(),
        onPaginationChange: setPagination,
        state: {
            pagination: pagination,
        },
    });
    return (React.createElement(React.Fragment, null,
        React.createElement("table", { className: "table-auto w-full" },
            React.createElement("thead", { className: "border-b-2 border-slate-400" }, table.getHeaderGroups().map(function (headerGroup) { return (React.createElement("tr", { key: headerGroup.id }, headerGroup.headers.map(function (header) { return (React.createElement("th", { key: header.id, className: "text-left" }, header.isPlaceholder
                ? null
                : flexRender(header.column.columnDef.header, header.getContext()))); }))); })),
            React.createElement("tbody", null, table.getRowModel().rows.map(function (row) { return (React.createElement("tr", { key: row.id, className: "even:bg-gray-800" }, row.getVisibleCells().map(function (cell) { return (React.createElement("td", { key: cell.id }, flexRender(cell.column.columnDef.cell, cell.getContext()))); }))); })),
            React.createElement("tfoot", null, table.getFooterGroups().map(function (footerGroup) { return (React.createElement("tr", { key: footerGroup.id }, footerGroup.headers.map(function (header) { return (React.createElement("th", { key: header.id }, header.isPlaceholder
                ? null
                : flexRender(header.column.columnDef.footer, header.getContext()))); }))); }))),
        React.createElement("div", { className: "mt-8 grid grid-cols-12 place-items-center gap-2" },
            React.createElement(TableButton, { text: "<<", onClick: function () { return table.setPageIndex(0); }, disabled: !table.getCanPreviousPage() }),
            React.createElement(TableButton, { text: "<", onClick: function () { return table.previousPage(); }, disabled: !table.getCanPreviousPage() }),
            React.createElement("div", { className: "col-span-3 grid w-full grid-rows-2 place-items-center" },
                React.createElement("span", { className: "flex items-center gap-1" },
                    React.createElement("div", null, "Page"),
                    React.createElement("strong", null,
                        table.getState().pagination.pageIndex + 1,
                        " of",
                        " ",
                        table.getPageCount()))),
            React.createElement(TableButton, { text: ">", onClick: function () { return table.nextPage(); }, disabled: !table.getCanNextPage() }),
            React.createElement(TableButton, { text: ">>", onClick: function () { return table.setPageIndex(table.getPageCount() - 1); }, disabled: !table.getCanNextPage() }))));
};
var MaintenanceWindowDisclosures = function (_a) {
    var pnid = _a.pnid;
    var getAccessTokenSilently = useAuth0().getAccessTokenSilently;
    var _b = useState(null), maintenanceWindows = _b[0], setMaintenanceWindows = _b[1];
    var fetchMaintenanceWindows = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var accessToken, response, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAccessTokenSilently({
                        authorizationParams: {
                            audience: process.env.AUTH0_AUDIENCE,
                        },
                    })];
                case 1:
                    accessToken = _a.sent();
                    return [4 /*yield*/, fetch("".concat(process.env.NOC_SVC_URL, "/powernodes/maintenance_window/").concat(pnid), {
                            headers: {
                                authorization: "Bearer ".concat(accessToken),
                            },
                        })];
                case 2:
                    response = _a.sent();
                    if (!response.ok) return [3 /*break*/, 4];
                    return [4 /*yield*/, response.json()];
                case 3:
                    data = _a.sent();
                    setMaintenanceWindows(data);
                    return [3 /*break*/, 5];
                case 4:
                    alert("Failed to fetch maintenance windows.");
                    _a.label = 5;
                case 5: return [2 /*return*/];
            }
        });
    }); }, [getAccessTokenSilently]);
    useMemo(function () {
        void fetchMaintenanceWindows();
        var intervalId = setInterval(fetchMaintenanceWindows, 2000);
        return function () { return clearInterval(intervalId); };
    }, [fetchMaintenanceWindows]);
    var maintenanceWindowProps = maintenanceWindows != null
        ? maintenanceWindows
            .map(function (mw) { return ({
            id: mw.id,
            start: new Date(mw.start),
            end: new Date(mw.end),
            deleted: mw.deleted,
        }); })
            .filter(function (mw) { return !mw.deleted && mw.end.getTime() >= new Date().getTime(); })
            .sort(function (a, b) {
            return a.start.getTime() - b.start.getTime();
        })
            .map(function (mw) {
            return {
                title: "Maintenance Window (".concat(mw.start.toLocaleString(), " - ").concat(mw.end.toLocaleString(), ")"),
                metadataUrl: "/powernodes/maintenance_window/metadata/".concat(mw.id),
                setMetadataUrl: "/powernodes/maintenance_window/metadata/set/".concat(mw.id),
                deleteUrl: "/powernodes/maintenance_window/delete/".concat(mw.id),
                uniqueId: mw.id,
            };
        })
        : [];
    return (React.createElement(React.Fragment, null, maintenanceWindowProps.map(function (mw) {
        return React.createElement(NOCControl, __assign({}, mw, { key: mw.uniqueId }));
    })));
};
var CreateMaintenanceWindowDisclosure = function (_a) {
    var pnid = _a.pnid;
    var powernodeParentConfig = {
        possibleParents: [
            {
                title: "".concat(pnid),
                id: pnid,
            },
        ],
        title: "Powernode",
        key: "powernodeId",
    };
    var creatableAssets = {
        title: "Create New Maintenance Window",
        createUrl: "/powernodes/maintenance_window/create",
        parent: powernodeParentConfig,
        subObjects: [
            {
                metadataUrl: "/powernodes/maintenance_window/metadata",
            },
        ],
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(NOCCreate, __assign({}, creatableAssets))));
};
var MaintenanceWindowDisplay = function (_a) {
    var pnid = _a.pnid;
    return (React.createElement(React.Fragment, null,
        React.createElement(MaintenanceWindowDisclosures, { pnid: pnid }),
        React.createElement(CreateMaintenanceWindowDisclosure, { pnid: pnid })));
};
var LegacyCommandDisclosure = function (_a) {
    var pnid = _a.pnid;
    var cmdInput = useRef(null);
    var _b = useState(""), cmdResponse = _b[0], setCmdResponse = _b[1];
    var _c = useState(true), cmdSuccess = _c[0], setCmdSuccess = _c[1];
    var _d = useAuth0(), getAccessTokenSilently = _d.getAccessTokenSilently, user = _d.user;
    var onSendClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var commandValue, accessToken, command, response;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    commandValue = (_a = cmdInput.current) === null || _a === void 0 ? void 0 : _a.value;
                    if (commandValue.length == 0) {
                        setCmdResponse("Invalid input.");
                        setCmdSuccess(false);
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, getAccessTokenSilently({
                            authorizationParams: {
                                audience: process.env.AUTH0_AUDIENCE,
                            },
                        })];
                case 1:
                    accessToken = _c.sent();
                    command = {
                        pnid: pnid,
                        command: commandValue,
                        username: (_b = user === null || user === void 0 ? void 0 : user.name) !== null && _b !== void 0 ? _b : user === null || user === void 0 ? void 0 : user.email,
                    };
                    if (!(confirm("Confirm command PNID: ".concat(pnid, ", CMD: ").concat(commandValue)) == true)) return [3 /*break*/, 3];
                    setCmdResponse("");
                    setCmdSuccess(true);
                    return [4 /*yield*/, fetch("".concat(process.env.NOC_SVC_URL, "/powernodes/commands/send_legacy"), {
                            method: "POST",
                            body: JSON.stringify(command),
                            headers: {
                                "Content-Type": "application/json",
                                authorization: "Bearer ".concat(accessToken),
                            },
                        })];
                case 2:
                    response = _c.sent();
                    cmdInput.current.value = "";
                    setCmdResponse("Ok: ".concat(response.ok, ", status: ").concat(response.status, ", statusText: ").concat(response.statusText));
                    setCmdSuccess(response.ok);
                    _c.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement("div", { className: "flex flex-col gap-y-[16px]" },
        React.createElement("div", { className: "flex flex-row gap-x-[32px]" },
            React.createElement("label", { className: "font-N7 text-white" }, "Command"),
            React.createElement("input", { className: "w-[1024px]", ref: cmdInput, onPaste: function (e) {
                    if (e.clipboardData) {
                        // This prevents leading and trailing whitespace from being pasted. This often happens when pasting from the command history table.
                        e.preventDefault();
                        e.currentTarget.value = e.clipboardData
                            .getData("text/plain")
                            .trim();
                    }
                } })),
        React.createElement("button", { className: "bg-red text-white w-fit px-[32px] py-[16px] rounded-[10px]", onClick: onSendClick }, "Send Command"),
        React.createElement(SuccessIndicator, { message: cmdResponse == "" ? undefined : cmdResponse, success: cmdSuccess })));
};
var JsonCommandDisclosure = function (_a) {
    var pnid = _a.pnid, details = _a.details;
    var SampleCommand;
    (function (SampleCommand) {
        SampleCommand["SEND_JSON_UPDATE_FIRMWARE"] = "Send OCPP JSON (update firmware)";
        SampleCommand["REBOOT_CHARGER"] = "Send OCPP JSON (reboot charger)";
    })(SampleCommand || (SampleCommand = {}));
    var getSampleMessage = function (command, ocppId) {
        switch (command) {
            case SampleCommand.SEND_JSON_UPDATE_FIRMWARE:
                return {
                    sendOcppMessage: {
                        message: "[2,\"".concat(uuidv4(), "\",\"UpdateFirmware\",{\"location\":\"http://192.168.225.31:80/fc200k_V2_AIO_EEra_0.4.zip\",\"retrieveDate\":\"").concat(new Date().toISOString(), "\"}]"),
                        ocppId: ocppId !== null && ocppId !== void 0 ? ocppId : "YOUR_ID_HERE",
                    },
                };
            case SampleCommand.REBOOT_CHARGER:
                return {
                    sendOcppMessage: {
                        message: "[2,\"".concat(uuidv4(), "\",\"Reset\",{\"type\":\"Hard\"}]"),
                        ocppId: ocppId !== null && ocppId !== void 0 ? ocppId : "YOUR_ID_HERE",
                    },
                };
        }
    };
    var cmdInput = useRef(null);
    var _b = useState(""), cmdResponse = _b[0], setCmdResponse = _b[1];
    var _c = useState(true), cmdSuccess = _c[0], setCmdSuccess = _c[1];
    var _d = useState(), selectedCharger = _d[0], setSelectedCharger = _d[1];
    var defaultItem = {
        name: "",
        value: undefined,
        selectedLabel: "Select Charger",
    };
    var dropdownItems = [defaultItem].concat(details.chargers
        .sort(function (c1, c2) { return c1.id - c2.id; })
        .map(function (c) {
        return {
            name: c.ocppId,
            value: c.ocppId,
            selectedLabel: c.ocppId,
        };
    }));
    var _e = useAuth0(), getAccessTokenSilently = _e.getAccessTokenSilently, user = _e.user;
    var onClickSampleCommand = function (command) {
        if (!cmdInput.current) {
            return;
        }
        var sampleMessage = getSampleMessage(command, selectedCharger);
        cmdInput.current.innerText = JSON.stringify(sampleMessage, null, 2);
    };
    var onClearClicked = function () {
        if (!cmdInput.current) {
            return;
        }
        cmdInput.current.innerText = "";
    };
    var onSendClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var commandValue, parsedCommand, accessToken, command, response;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    commandValue = (_a = cmdInput.current) === null || _a === void 0 ? void 0 : _a.innerText;
                    if (!commandValue) {
                        setCmdResponse("Invalid input.");
                        setCmdSuccess(false);
                        return [2 /*return*/];
                    }
                    try {
                        parsedCommand = JSON.parse(commandValue);
                    }
                    catch (e) {
                        setCmdResponse("Invalid input. Failed to parse JSON: " + e);
                        setCmdSuccess(false);
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, getAccessTokenSilently({
                            authorizationParams: {
                                audience: process.env.AUTH0_AUDIENCE,
                            },
                        })];
                case 1:
                    accessToken = _c.sent();
                    command = {
                        pnid: pnid,
                        command: parsedCommand,
                        username: (_b = user === null || user === void 0 ? void 0 : user.name) !== null && _b !== void 0 ? _b : user === null || user === void 0 ? void 0 : user.email,
                    };
                    if (!(confirm("Confirm command PNID: ".concat(pnid, ", CMD: ").concat(commandValue)) == true)) return [3 /*break*/, 3];
                    setCmdResponse("");
                    setCmdSuccess(true);
                    return [4 /*yield*/, fetch("".concat(process.env.NOC_SVC_URL, "/powernodes/commands/send"), {
                            method: "POST",
                            body: JSON.stringify(command),
                            headers: {
                                "Content-Type": "application/json",
                                authorization: "Bearer ".concat(accessToken),
                            },
                        })];
                case 2:
                    response = _c.sent();
                    cmdInput.current.innerText = "";
                    setCmdResponse("Ok: ".concat(response.ok, ", status: ").concat(response.status, ", statusText: ").concat(response.statusText));
                    setCmdSuccess(response.ok);
                    _c.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var onPaste = function (e) {
        if (cmdInput.current) {
            e.preventDefault();
            var text = e.clipboardData.getData("text/plain");
            window.document.execCommand("insertHTML", false, text);
        }
    };
    return (React.createElement("div", { className: "flex flex-col gap-y-[16px]" },
        React.createElement("div", { className: "flex flex-row flex-wrap gap-4 items-center" },
            React.createElement("label", { className: "font-N7 text-white" }, "Placeholders"),
            React.createElement(Dropdown, { items: dropdownItems, currentSelection: selectedCharger, onClick: setSelectedCharger })),
        React.createElement("hr", { className: "border-black-800 max-w-[25%] my-2" }),
        React.createElement("div", { className: "flex flex-row flex-wrap gap-4" },
            React.createElement(StyledButton, { text: "CLEAR", onClick: onClearClicked, style: StyledButtonStyle.Gray }),
            Object.values(SampleCommand).map(function (c) {
                return (React.createElement(StyledButton, { text: c, onClick: function () { return onClickSampleCommand(c); }, key: c }));
            })),
        React.createElement("hr", { className: "border-black-800 max-w-[25%] my-2" }),
        React.createElement("div", { contentEditable: true, ref: cmdInput, onPaste: onPaste, className: "p-[16px] whitespace-pre w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" }),
        React.createElement("button", { className: "bg-red text-white w-fit px-[32px] py-[16px] rounded-[10px]", onClick: onSendClick }, "Send Command"),
        React.createElement(SuccessIndicator, { message: cmdResponse == "" ? undefined : cmdResponse, success: cmdSuccess })));
};
var GUICommandDisclosure = function (_a) {
    var pnid = _a.pnid;
    var _b = useState(null), maintenanceMode = _b[0], setMaintenanceMode = _b[1];
    var _c = useAuth0(), getAccessTokenSilently = _c.getAccessTokenSilently, user = _c.user;
    var fetchMaintenanceModeStatus = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var accessToken, response, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAccessTokenSilently({
                        authorizationParams: {
                            audience: process.env.AUTH0_AUDIENCE,
                        },
                    })];
                case 1:
                    accessToken = _a.sent();
                    return [4 /*yield*/, fetch("".concat(process.env.NOC_SVC_URL, "/powernodes/status/").concat(pnid), {
                            headers: {
                                authorization: "Bearer ".concat(accessToken),
                            },
                        })];
                case 2:
                    response = _a.sent();
                    if (!response) return [3 /*break*/, 4];
                    return [4 /*yield*/, response.json()];
                case 3:
                    data = _a.sent();
                    switch (events_svc.powernodeStatusFromJSON(data)) {
                        case events_svc.PowernodeStatus.MAINTENANCE:
                            setMaintenanceMode("On");
                            break;
                        case events_svc.PowernodeStatus.ONLINE:
                            setMaintenanceMode("Off");
                            break;
                        default:
                            setMaintenanceMode("Unknown");
                            break;
                    }
                    return [3 /*break*/, 5];
                case 4:
                    alert("Failed to fetch status.");
                    _a.label = 5;
                case 5: return [2 /*return*/];
            }
        });
    }); }, [getAccessTokenSilently, setMaintenanceMode]);
    var sendCommand = function (command) { return __awaiter(void 0, void 0, void 0, function () {
        var accessToken, legacyCommand;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, getAccessTokenSilently({
                        authorizationParams: {
                            audience: process.env.AUTH0_AUDIENCE,
                        },
                    })];
                case 1:
                    accessToken = _b.sent();
                    legacyCommand = {
                        pnid: pnid,
                        command: command,
                        username: (_a = user === null || user === void 0 ? void 0 : user.name) !== null && _a !== void 0 ? _a : user === null || user === void 0 ? void 0 : user.email,
                    };
                    return [4 /*yield*/, fetch("".concat(process.env.NOC_SVC_URL, "/powernodes/commands/send_legacy"), {
                            method: "POST",
                            body: JSON.stringify(legacyCommand),
                            headers: {
                                "Content-Type": "application/json",
                                authorization: "Bearer ".concat(accessToken),
                            },
                        })];
                case 2:
                    _b.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        var interval = setInterval(fetchMaintenanceModeStatus, 1000);
        return function () { return clearInterval(interval); };
    }, [fetchMaintenanceModeStatus]);
    return (React.createElement("div", { className: "flex flex-col gap-y-[16px]" },
        React.createElement("div", { className: "mt-4" },
            React.createElement("p", null,
                "Maintenance Mode Status: ", maintenanceMode !== null && maintenanceMode !== void 0 ? maintenanceMode : "Loading...")),
        React.createElement("div", { className: "flex flex-row flex-wrap gap-x-[16px]" },
            React.createElement(StyledButton, { text: "Maintenance Mode ON", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(confirm("Confirm to turn maintenance mode on at powernode ".concat(pnid)) == true)) return [3 /*break*/, 2];
                                return [4 /*yield*/, sendCommand("sc1 pn_ctrl_manual_inputs maintenance_mode 1")];
                            case 1:
                                _a.sent();
                                _a.label = 2;
                            case 2: return [2 /*return*/];
                        }
                    });
                }); }, style: StyledButtonStyle.Gray }),
            React.createElement(StyledButton, { text: "Maintenance Mode OFF", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(confirm("Confirm to turn maintenance mode off at powernode ".concat(pnid)) == true)) return [3 /*break*/, 2];
                                return [4 /*yield*/, sendCommand("sc1 pn_ctrl_manual_inputs maintenance_mode 0")];
                            case 1:
                                _a.sent();
                                _a.label = 2;
                            case 2: return [2 /*return*/];
                        }
                    });
                }); }, style: StyledButtonStyle.Green }))));
};
var ATPCommandDisclosure = function (_a) {
    var pnid = _a.pnid;
    var cmdRechargeLimitInput = useRef(null);
    var cmdPowerCommandInput = useRef(null);
    var _b = useState(""), sendRechargeSocLimitResponse = _b[0], setSendRechargeSocLimitResponse = _b[1];
    var _c = useState(false), sendRechargeSocLimitSuccess = _c[0], setSendRechargeSocLimitSuccess = _c[1];
    var _d = useState(""), sendPowerCommandResponse = _d[0], setSendPowerCommandResponse = _d[1];
    var _e = useState(false), sendPowerCommandSuccess = _e[0], setSendPowerCommandSuccess = _e[1];
    var _f = useAuth0(), getAccessTokenSilently = _f.getAccessTokenSilently, user = _f.user;
    var onSetRechargeSocLimitClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var rechargeSocLimitValue, accessToken, command, response;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!cmdRechargeLimitInput.current) {
                        console.error("onSetRechargeSocLimitClick");
                        return [2 /*return*/];
                    }
                    rechargeSocLimitValue = cmdRechargeLimitInput.current.value;
                    if (rechargeSocLimitValue.length === 0) {
                        setSendRechargeSocLimitResponse("Invalid input.");
                        setSendRechargeSocLimitSuccess(false);
                        return [2 /*return*/];
                    }
                    if (isNaN(rechargeSocLimitValue)) {
                        setSendRechargeSocLimitResponse("Invalid input. Must be a number.");
                        setSendRechargeSocLimitSuccess(false);
                        return [2 /*return*/];
                    }
                    if (Number(rechargeSocLimitValue) > 100 ||
                        Number(rechargeSocLimitValue) < 0) {
                        setSendRechargeSocLimitResponse("Invalid input. Must be between 0-100%.");
                        setSendRechargeSocLimitSuccess(false);
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, getAccessTokenSilently({
                            authorizationParams: {
                                audience: process.env.AUTH0_AUDIENCE,
                            },
                        })];
                case 1:
                    accessToken = _b.sent();
                    command = {
                        pnid: pnid,
                        command: "sc1 pn_ctrl_manual_inputs atp_recharge_soc_target ".concat(rechargeSocLimitValue),
                        username: (_a = user === null || user === void 0 ? void 0 : user.name) !== null && _a !== void 0 ? _a : user === null || user === void 0 ? void 0 : user.email,
                    };
                    if (!(confirm("Confirm Set Recharge SOC Limit for PNID: ".concat(pnid, ", Limit: ").concat(rechargeSocLimitValue, "%")) === true)) return [3 /*break*/, 3];
                    setSendRechargeSocLimitResponse("");
                    setSendRechargeSocLimitSuccess(true);
                    return [4 /*yield*/, fetch("".concat(process.env.NOC_SVC_URL, "/powernodes/commands/send_legacy"), {
                            method: "POST",
                            body: JSON.stringify(command),
                            headers: {
                                "Content-Type": "application/json",
                                authorization: "Bearer ".concat(accessToken),
                            },
                        })];
                case 2:
                    response = _b.sent();
                    cmdRechargeLimitInput.current.value = "";
                    setSendRechargeSocLimitResponse("Ok: ".concat(response.ok, ", status: ").concat(response.status, ", statusText: ").concat(response.statusText));
                    setSendRechargeSocLimitSuccess(response.ok);
                    _b.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var onSetPowerCommandClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var powerCommandValue, accessToken, command, response;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!cmdPowerCommandInput.current) {
                        console.error("onSetPowerCommandClick");
                        return [2 /*return*/];
                    }
                    powerCommandValue = cmdPowerCommandInput.current.value;
                    if (powerCommandValue.length === 0) {
                        setSendPowerCommandResponse("Invalid input.");
                        setSendPowerCommandSuccess(false);
                        return [2 /*return*/];
                    }
                    // Check for valid number
                    if (isNaN(Number(powerCommandValue))) {
                        setSendPowerCommandResponse("Invalid input. Must be a number.");
                        setSendPowerCommandSuccess(false);
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, getAccessTokenSilently({
                            authorizationParams: {
                                audience: process.env.AUTH0_AUDIENCE,
                            },
                        })];
                case 1:
                    accessToken = _b.sent();
                    command = {
                        pnid: pnid,
                        command: "sc1 pn_ctrl_manual_inputs p_ac_cmd_kw ".concat(powerCommandValue),
                        username: (_a = user === null || user === void 0 ? void 0 : user.name) !== null && _a !== void 0 ? _a : user === null || user === void 0 ? void 0 : user.email,
                    };
                    if (!(confirm("Confirm Set Power Command for PNID: ".concat(pnid, ", Command: ").concat(powerCommandValue, "kW")) === true)) return [3 /*break*/, 3];
                    setSendPowerCommandResponse("");
                    setSendPowerCommandSuccess(true);
                    return [4 /*yield*/, fetch("".concat(process.env.NOC_SVC_URL, "/powernodes/commands/send_legacy"), {
                            method: "POST",
                            body: JSON.stringify(command),
                            headers: {
                                "Content-Type": "application/json",
                                authorization: "Bearer ".concat(accessToken),
                            },
                        })];
                case 2:
                    response = _b.sent();
                    cmdPowerCommandInput.current.value = "";
                    setSendPowerCommandResponse("Ok: ".concat(response.ok, ", status: ").concat(response.status, ", statusText: ").concat(response.statusText));
                    setSendPowerCommandSuccess(response.ok);
                    _b.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var sendCommand = function (command) { return __awaiter(void 0, void 0, void 0, function () {
        var accessToken, legacyCommand;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, getAccessTokenSilently({
                        authorizationParams: {
                            audience: process.env.AUTH0_AUDIENCE,
                        },
                    })];
                case 1:
                    accessToken = _b.sent();
                    legacyCommand = {
                        pnid: pnid,
                        command: command,
                        username: (_a = user === null || user === void 0 ? void 0 : user.name) !== null && _a !== void 0 ? _a : user === null || user === void 0 ? void 0 : user.email,
                    };
                    return [4 /*yield*/, fetch("".concat(process.env.NOC_SVC_URL, "/powernodes/commands/send_legacy"), {
                            method: "POST",
                            body: JSON.stringify(legacyCommand),
                            headers: {
                                "Content-Type": "application/json",
                                authorization: "Bearer ".concat(accessToken),
                            },
                        })];
                case 2:
                    _b.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement("div", { className: "flex flex-col gap-y-[16px]" },
        React.createElement("div", { className: "flex flex-row flex-wrap gap-x-[16px]" },
            React.createElement(StyledButton, { text: "ATP MODE OFF", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(confirm("Confirm to DISABLE ATP MODE at powernode ".concat(pnid)) ===
                                    true)) return [3 /*break*/, 2];
                                return [4 /*yield*/, sendCommand("sc1 pn_ctrl_manual_inputs en_batt_ctrl_override 0")];
                            case 1:
                                _a.sent();
                                _a.label = 2;
                            case 2: return [2 /*return*/];
                        }
                    });
                }); }, style: StyledButtonStyle.Gray }),
            React.createElement(StyledButton, { text: "ATP MODE ON", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(confirm("Confirm to ENABLE ATP MODE at powernode ".concat(pnid)) ===
                                    true)) return [3 /*break*/, 5];
                                return [4 /*yield*/, sendCommand("sc1 pn_ctrl_manual_inputs fsm_cmd 0")];
                            case 1:
                                _a.sent();
                                return [4 /*yield*/, sendCommand("sc1 pn_ctrl_manual_inputs p_ac_cmd_kw 0")];
                            case 2:
                                _a.sent();
                                return [4 /*yield*/, sendCommand("sc1 pn_ctrl_manual_inputs thermal_override 0")];
                            case 3:
                                _a.sent();
                                return [4 /*yield*/, sendCommand("sc1 pn_ctrl_manual_inputs en_batt_ctrl_override 1")];
                            case 4:
                                _a.sent();
                                _a.label = 5;
                            case 5: return [2 /*return*/];
                        }
                    });
                }); }, style: StyledButtonStyle.Gray }),
            React.createElement(StyledButton, { text: "SAFE PACK", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(confirm("Confirm to SAFE PACK at powernode ".concat(pnid)) === true)) return [3 /*break*/, 2];
                                return [4 /*yield*/, sendCommand("sc1 pn_ctrl_manual_inputs fsm_cmd 0")];
                            case 1:
                                _a.sent();
                                _a.label = 2;
                            case 2: return [2 /*return*/];
                        }
                    });
                }); }, style: StyledButtonStyle.Gray }),
            React.createElement(StyledButton, { text: "IDLE", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(confirm("Confirm to take pack into IDLE at powernode ".concat(pnid)) ===
                                    true)) return [3 /*break*/, 2];
                                return [4 /*yield*/, sendCommand("sc1 pn_ctrl_manual_inputs fsm_cmd 2")];
                            case 1:
                                _a.sent();
                                _a.label = 2;
                            case 2: return [2 /*return*/];
                        }
                    });
                }); }, style: StyledButtonStyle.Gray }),
            React.createElement(StyledButton, { text: "LIVE", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(confirm("Confirm to take pack into LIVE at powernode ".concat(pnid, ". THIS WILL START RESPONDING TO POWER COMMANDS! ENSURE POWER COMMAND IS SET TO SAFE VALUE OR 0KW!!")) === true)) return [3 /*break*/, 2];
                                return [4 /*yield*/, sendCommand("sc1 pn_ctrl_manual_inputs fsm_cmd 3")];
                            case 1:
                                _a.sent();
                                _a.label = 2;
                            case 2: return [2 /*return*/];
                        }
                    });
                }); }, style: StyledButtonStyle.Gray }),
            React.createElement(StyledButton, { text: "CLEAR FAULTS", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(confirm("Confirm to take pack into CLEAR FAULTS at powernode ".concat(pnid)) === true)) return [3 /*break*/, 2];
                                return [4 /*yield*/, sendCommand("sc1 pn_ctrl_manual_inputs fsm_cmd 4")];
                            case 1:
                                _a.sent();
                                _a.label = 2;
                            case 2: return [2 /*return*/];
                        }
                    });
                }); }, style: StyledButtonStyle.Gray }),
            React.createElement(StyledButton, { text: "THERMAL OVERRIDE ON", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(confirm("Confirm to override control enclosure thermal management at powernode ".concat(pnid)) === true)) return [3 /*break*/, 2];
                                return [4 /*yield*/, sendCommand("sc1 pn_ctrl_manual_inputs thermal_override 1")];
                            case 1:
                                _a.sent();
                                _a.label = 2;
                            case 2: return [2 /*return*/];
                        }
                    });
                }); }, style: StyledButtonStyle.Gray }),
            React.createElement(StyledButton, { text: "THERMAL OVERRIDE OFF", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(confirm("Confirm to disable override control enclosure thermal management at powernode ".concat(pnid)) === true)) return [3 /*break*/, 2];
                                return [4 /*yield*/, sendCommand("sc1 pn_ctrl_manual_inputs thermal_override 0")];
                            case 1:
                                _a.sent();
                                _a.label = 2;
                            case 2: return [2 /*return*/];
                        }
                    });
                }); }, style: StyledButtonStyle.Gray }),
            React.createElement(StyledButton, { text: "TEC ON", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(confirm("Confirm to command TEC to ON at powernode ".concat(pnid)) ===
                                    true)) return [3 /*break*/, 2];
                                return [4 /*yield*/, sendCommand("sc1 pn_ctrl_manual_inputs tec_power_pin 1")];
                            case 1:
                                _a.sent();
                                _a.label = 2;
                            case 2: return [2 /*return*/];
                        }
                    });
                }); }, style: StyledButtonStyle.Gray }),
            React.createElement(StyledButton, { text: "TEC OFF", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(confirm("Confirm to command TEC to OFF at powernode ".concat(pnid)) ===
                                    true)) return [3 /*break*/, 2];
                                return [4 /*yield*/, sendCommand("sc1 pn_ctrl_manual_inputs tec_power_pin 0")];
                            case 1:
                                _a.sent();
                                _a.label = 2;
                            case 2: return [2 /*return*/];
                        }
                    });
                }); }, style: StyledButtonStyle.Gray })),
        React.createElement("div", null,
            React.createElement("div", { className: "flex flex-row gap-x-[32px]" },
                React.createElement("label", { className: "font-N7 text-white" }, "Recharge SOC Limit (%)"),
                React.createElement("input", { className: "w-[8em] outline outline-2 hover:outline hover:outline-2 focus:outline focus:outline-2", ref: cmdRechargeLimitInput, onPaste: function (e) {
                        if (e.clipboardData) {
                            // This prevents leading and trailing whitespace from being pasted. This often happens when pasting from the command history table.
                            e.preventDefault();
                            e.currentTarget.value = e.clipboardData
                                .getData("text/plain")
                                .trim();
                        }
                    } })),
            React.createElement("button", { className: "bg-red text-white w-fit px-[8px] py-[8px] rounded-[10px]", onClick: onSetRechargeSocLimitClick }, "Set Recharge SOC Limit"),
            React.createElement(SuccessIndicator, { message: sendRechargeSocLimitResponse == ""
                    ? undefined
                    : sendRechargeSocLimitResponse, success: sendRechargeSocLimitSuccess })),
        React.createElement("div", null,
            React.createElement("div", { className: "flex flex-row gap-x-[32px]" },
                React.createElement("label", { className: "font-N7 text-white" }, "Power Command (kW)"),
                React.createElement("input", { className: "w-[8em] outline outline-2 hover:outline hover:outline-2 focus:outline focus:outline-2", ref: cmdPowerCommandInput, onPaste: function (e) {
                        if (e.clipboardData) {
                            // This prevents leading and trailing whitespace from being pasted. This often happens when pasting from the command history table.
                            e.preventDefault();
                            e.currentTarget.value = e.clipboardData
                                .getData("text/plain")
                                .trim();
                        }
                    } })),
            React.createElement("button", { className: "bg-red text-white w-fit px-[8px] py-[8px] rounded-[10px]", onClick: onSetPowerCommandClick }, "Set Power Command")),
        React.createElement(SuccessIndicator, { message: sendPowerCommandResponse == "" ? undefined : sendPowerCommandResponse, success: sendPowerCommandSuccess })));
};
var CommandPanel = function (_a) {
    var pnid = _a.pnid, details = _a.details, isNoc = _a.isNoc, hasChargePointVendorAccess = _a.hasChargePointVendorAccess;
    return (React.createElement("div", { className: "flex flex-col gap-y-[16px]" },
        React.createElement("hr", { className: "border-black-800 max-w-[50%] my-8" }),
        React.createElement("label", { className: "font-N6" }, "PNID ".concat(pnid)),
        isNoc && React.createElement(MaintenanceWindowDisplay, { pnid: pnid }),
        isNoc && (React.createElement(Disclosure, null, function (_a) {
            var open = _a.open;
            return (React.createElement(React.Fragment, null,
                React.createElement(Disclosure.Button, { className: "bg-black-1000 hover:bg-black-800 rounded-[10px] px-[32px] py-[16px] flex flex-row gap-x-[32px] w-full justify-between" },
                    React.createElement("label", { className: "font-N6" }, "Legacy Command"),
                    open ? (React.createElement(ChevronDownIcon, { className: "w-[32px] h-[32px]" })) : (React.createElement(ChevronUpIcon, { className: "w-[32px] h-[32px]" }))),
                React.createElement(Disclosure.Panel, { className: "w-full" },
                    React.createElement(LegacyCommandDisclosure, { pnid: pnid }))));
        })),
        (isNoc || hasChargePointVendorAccess) && (React.createElement(Disclosure, null, function (_a) {
            var open = _a.open;
            return (React.createElement(React.Fragment, null,
                React.createElement(Disclosure.Button, { className: "bg-black-1000 hover:bg-black-800 rounded-[10px] px-[32px] py-[16px] flex flex-row gap-x-[32px] w-full justify-between" },
                    React.createElement("label", { className: "font-N6" }, "JSON Command"),
                    open ? (React.createElement(ChevronDownIcon, { className: "w-[32px] h-[32px]" })) : (React.createElement(ChevronUpIcon, { className: "w-[32px] h-[32px]" }))),
                React.createElement(Disclosure.Panel, { className: "w-full" },
                    React.createElement(JsonCommandDisclosure, { pnid: pnid, details: details }))));
        })),
        isNoc && (React.createElement(React.Fragment, null,
            React.createElement(Disclosure, null, function (_a) {
                var open = _a.open;
                return (React.createElement(React.Fragment, null,
                    React.createElement(Disclosure.Button, { className: "bg-black-1000 hover:bg-black-800 rounded-[10px] px-[32px] py-[16px] flex flex-row gap-x-[32px] w-full justify-between" },
                        React.createElement("label", { className: "font-N6" }, "GUI Command"),
                        open ? (React.createElement(ChevronDownIcon, { className: "w-[32px] h-[32px]" })) : (React.createElement(ChevronUpIcon, { className: "w-[32px] h-[32px]" }))),
                    React.createElement(Disclosure.Panel, { className: "w-full" },
                        React.createElement(GUICommandDisclosure, { pnid: pnid }))));
            }),
            React.createElement(Disclosure, null, function (_a) {
                var open = _a.open;
                return (React.createElement(React.Fragment, null,
                    React.createElement(Disclosure.Button, { className: "bg-black-1000 hover:bg-black-800 rounded-[10px] px-[32px] py-[16px] flex flex-row gap-x-[32px] w-full justify-between" },
                        React.createElement("label", { className: "font-N6" }, "ATP Command"),
                        open ? (React.createElement(ChevronDownIcon, { className: "w-[32px] h-[32px]" })) : (React.createElement(ChevronUpIcon, { className: "w-[32px] h-[32px]" }))),
                    React.createElement(Disclosure.Panel, { className: "w-full" },
                        React.createElement(ATPCommandDisclosure, { pnid: pnid }))));
            }),
            React.createElement(Disclosure, null, function (_a) {
                var open = _a.open;
                return (React.createElement(React.Fragment, null,
                    React.createElement(Disclosure.Button, { className: "bg-black-1000 hover:bg-black-800 rounded-[10px] px-[32px] py-[16px] flex flex-row gap-x-[32px] w-full justify-between" },
                        React.createElement("label", { className: "font-N6" }, "Command Log"),
                        open ? (React.createElement(ChevronDownIcon, { className: "w-[32px] h-[32px]" })) : (React.createElement(ChevronUpIcon, { className: "w-[32px] h-[32px]" }))),
                    React.createElement(Disclosure.Panel, { className: "w-full" },
                        React.createElement(CommandHistoryTable, { pnid: pnid }))));
            })))));
};
export var NOCCommands = function (_a) {
    var details = _a.details, isNoc = _a.isNoc, hasChargePointVendorAccess = _a.hasChargePointVendorAccess;
    return (React.createElement("div", { className: "flex flex-col gap-y-[16px]" },
        React.createElement("label", { className: "font-N5 text-white" }, "NOC Commands"),
        details &&
            details.powernodes
                .sort(function (a, b) { return a.id - b.id; })
                .map(function (pn) {
                return (React.createElement(CommandPanel, { pnid: pn.id, key: pn.id, details: details, isNoc: isNoc, hasChargePointVendorAccess: hasChargePointVendorAccess }));
            })));
};
