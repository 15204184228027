var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { process } from "../../Process";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import useSWR from "swr";
import { Auth0Fetcher } from "../../Auth0Fetcher";
import { GetCarInformationFromEquipmentManufacturer, GetMarketShareFromEquipmentManufacturer, GetDisplayPrice, MovingAverage, } from "ee-utils";
import { TextInput } from "../Input";
import { Radio } from "../Radio";
import { convertStringArrayToMultiSelectDropdownItems, MultiSelectDropdown, } from "../MultiSelectDropdown";
import { LineGraph } from "../Graph/LineGraph";
import { StatCard, RadialStatCard } from "../Card";
import { StaticTable } from "../Table";
var StatsBreakdown = function (_a) {
    var _b, _c, _d, _e;
    var regularTransactionStats = _a.stats, loyaltyTransactionStats = _a.loyaltyStats, movingAverageWindowSizeDays = _a.movingAverageWindowSizeDays;
    // Pull out the aggregate stats.
    var aggregate = regularTransactionStats.aggregateStats;
    // Session success rate to use elsewhere.
    var sessionSuccessRate = aggregate.totalSessions
        ? (aggregate.totalSuccessfulSessions / aggregate.totalSessions) * 100
        : 0;
    // Text to display on the session counts card.
    var sessionCardText = "".concat(aggregate.totalSuccessfulSessions, " | ").concat(aggregate.totalSessions - aggregate.totalSuccessfulSessions, " | ").concat(aggregate.totalSessions, " Sessions");
    // Text to display on the session duration card.
    var s = Math.round(aggregate.averageSessionLength) % 60;
    var m = Math.floor(aggregate.averageSessionLength / 60);
    var durationCardText = "".concat(m, "m ").concat(s, "s");
    // Calculate the total number of times feedback was given on the charger UI.
    var totalFeedback = aggregate.feedback.feedbackBuckets.reduce(function (a, b) { return a + b[1]; }, 0);
    // Get the average feedback rating from the charger UI.
    var averageFeedback = aggregate.feedback.feedbackBuckets.reduce(function (a, b) { return a + b[0] * b[1]; }, 0) /
        totalFeedback;
    // Build out graph data - sessions and session reliability trend.
    var spdMovingAverage = new MovingAverage(movingAverageWindowSizeDays, regularTransactionStats === null || regularTransactionStats === void 0 ? void 0 : regularTransactionStats.dailySessions[0]);
    var spdSuccessfulMovingAverage = new MovingAverage(movingAverageWindowSizeDays, regularTransactionStats === null || regularTransactionStats === void 0 ? void 0 : regularTransactionStats.dailySuccessfulSessions[0]);
    var graphSpdLabelSet = new Set();
    var graphSessionsData = regularTransactionStats
        ? regularTransactionStats.dailySessions.map(function (rawSpd, idx) {
            // Get data from the other arrays we need, and process through moving average filters.
            var rawSuccessfulSpd = regularTransactionStats.dailySuccessfulSessions[idx];
            spdMovingAverage.add(rawSpd);
            var spd = spdMovingAverage.get();
            spdSuccessfulMovingAverage.add(rawSuccessfulSpd);
            var spdSuccessful = spdSuccessfulMovingAverage.get();
            // Build timestamp.
            var d = new Date(aggregate.startTime);
            d.setDate(d.getDate() + idx);
            // Set up graph labels.
            var label = d.toLocaleDateString([], {
                month: "numeric",
                day: "numeric",
                year: "numeric",
            });
            graphSpdLabelSet.add(label);
            return {
                label: label,
                tooltip: d.toLocaleDateString([], {
                    month: "numeric",
                    day: "numeric",
                    year: "numeric",
                }),
                spd: spd,
                spdFailed: spd - spdSuccessful,
                spdSuccessful: spdSuccessful,
                sessionReliability: (spdSuccessful / spd) * 100,
            };
        })
        : [];
    var graphSessionsLabelAccessor = function (d) {
        return d.label;
    };
    var graphSpdValueAccessors = [
        {
            label: "Sessions Per Day",
            accessor: function (d) { return d.spd; },
        },
        {
            label: "Failed SPD",
            accessor: function (d) { return d.spdFailed; },
        },
        {
            label: "Successful SPD",
            accessor: function (d) { return d.spdSuccessful; },
        },
    ];
    var graphSessionReliabilityValueAccessors = [
        {
            label: "Session Reliability",
            accessor: function (d) { return d.sessionReliability; },
        },
    ];
    var graphSessionsTooltipAccessor = function (d) {
        return "".concat(d.tooltip);
    };
    // Define a table for fault code analysis.
    var faultCodeTableData = aggregate.errorCodes
        .sort(function (e1, e2) {
        if (e1.timesFatal == e2.timesFatal) {
            return Number(e1.errorCode) - Number(e2.errorCode);
        }
        return e2.timesFatal - e1.timesFatal;
    })
        .map(function (e) {
        // Calculate the market share of EVs affected by this fault code.
        var affectedMarketShare = e.manufacturerBreakdown.reduce(function (sum, cur) {
            var _a;
            return (sum + ((_a = GetMarketShareFromEquipmentManufacturer(cur[0])) !== null && _a !== void 0 ? _a : 0) * 100);
        }, 0);
        return {
            faultCode: e.errorCode,
            timesFatal: e.timesFatal,
            timesSeen: e.timesSeen,
            fatalRate: "".concat(((e.timesFatal / e.timesSeen) * 100).toFixed(1), "%"),
            reliabilityRate: "".concat(((e.timesFatal / aggregate.totalSessions) *
                100).toFixed(1), "%"),
            affectedKnownMarketShare: "".concat(affectedMarketShare.toFixed(1), "%"),
        };
    });
    var faultCodeTableColumns = [
        {
            header: "Error Code",
            accessorKey: "faultCode",
        },
        {
            header: "Failed Session Count",
            accessorKey: "timesFatal",
        },
        {
            header: "Times Seen",
            accessorKey: "timesSeen",
        },
        {
            header: "Affects Reliability Pct.",
            accessorKey: "fatalRate",
            helpText: "The percentage of the time this error code affects session reliability.",
        },
        {
            header: "Reliability Impact Pct.",
            accessorKey: "reliabilityRate",
            helpText: "The percentage of the time this error occurs over the number of sessions.",
        },
        {
            header: "Affected Known Market Share",
            accessorKey: "affectedKnownMarketShare",
            helpText: "The percentage of EV market share known to be affected by this fault.",
        },
    ];
    // Define a table for manufacturer coverage.
    var totalMarketShareTested = 0;
    var totalSessionsWithManufacturer = 0;
    var ccsManufacturerTableData = aggregate.manufacturerStats
        .sort(function (m1, m2) {
        if (m1.totalTransactions == m2.totalTransactions) {
            return Number(m1.failedTransactions) - Number(m2.failedTransactions);
        }
        return m2.totalTransactions - m1.totalTransactions;
    })
        .map(function (m) {
        var _a, _b, _c;
        // Get the vehicle manufacturer information for this equipment manufacturer.
        var carInfo = GetCarInformationFromEquipmentManufacturer(m.manufacturer);
        var vehicleMfgs = "";
        if (carInfo) {
            var allCarMfgs = ((_a = carInfo.confirmedCarManufacturers) !== null && _a !== void 0 ? _a : []).concat((_b = carInfo.suspectedCarManufacturers) !== null && _b !== void 0 ? _b : []);
            vehicleMfgs += allCarMfgs.join(", ");
        }
        // Determine market share for this manufacturer and add it to the running sum.
        var marketShare = ((_c = GetMarketShareFromEquipmentManufacturer(m.manufacturer)) !== null && _c !== void 0 ? _c : 0) * 100;
        totalMarketShareTested += marketShare;
        // Add to the running total of sessions with mfg. information.
        totalSessionsWithManufacturer += m.totalTransactions;
        return {
            mfgName: m.manufacturer,
            transactionCount: m.totalTransactions,
            vehicleMfgs: vehicleMfgs,
            knownMarketShare: "".concat(marketShare.toFixed(1), "%"),
        };
    });
    var ccsManufacturerTableColumns = [
        {
            header: "Manufacturer Name",
            accessorKey: "mfgName",
            helpText: "The name of this manufacturer (of ISO 15118 / CCS) comm HW and SW.",
        },
        {
            header: "Sessions",
            accessorKey: "transactionCount",
            helpText: "The number of sessions confirmed to match this manufacturer's EVCCID signature.",
        },
        {
            header: "Vehicle Manufacturers",
            accessorKey: "vehicleMfgs",
            helpText: "The confirmed or suspected vehicle manufacturers associated with this ISO 15118 / CCS manufacturer.",
        },
        {
            header: "Known Market Share",
            accessorKey: "knownMarketShare",
            helpText: "The percentage of EV market share known to be represented by this manufacturer.",
        },
    ];
    var loyaltyTableColumns = [
        {
            header: "EVCCID",
            accessorKey: "evccid",
        },
        {
            header: "Sessions",
            accessorKey: "count",
        },
        {
            header: "Latest Transaction Id",
            accessorKey: "latestTransaction.transactionDetail.transactionId",
        },
        {
            header: "Last Session Date",
            accessorKey: "latestTransaction.chargingSessionEndUtc",
        },
        {
            header: "Total Amount Spent",
            accessorKey: "totalAmountSpent",
        },
        {
            header: "Total Energy Delivered",
            accessorKey: "totalConsumedEnergyKwh",
        },
    ];
    return (React.createElement("div", { className: "flex flex-col gap-y-[16px] mb-16" },
        React.createElement("label", { className: "font-N7 text-white" }, "Time Range: FROM ".concat(new Date(aggregate.startTime).toLocaleString(), " TO ").concat(new Date(aggregate.endTime).toLocaleString(), " (in local time)")),
        React.createElement("hr", { className: "border-black-800 max-w-[50%]" }),
        React.createElement("label", { className: "font-N6 text-white mt-8" }, "Session Statistics"),
        React.createElement("div", { className: "flex flex-row flex-wrap gap-x-[16px]" },
            React.createElement(StatCard, { title: "Successful / Failed / Total Sessions", value: sessionCardText }),
            React.createElement(RadialStatCard, { title: "Session Reliability", value: sessionSuccessRate / 100 }),
            React.createElement(StatCard, { title: "Sessions Per Day", value: "".concat(aggregate.sessionsPerDay.toFixed(2)) }),
            React.createElement(StatCard, { title: "Average Session Length", value: durationCardText })),
        React.createElement(LineGraph, { labelAccessor: graphSessionsLabelAccessor, valueAccessors: graphSpdValueAccessors, data: graphSessionsData, yAxisValueToLabel: function (v) { return "".concat(v.toFixed(2), " SPD"); }, manualOrder: true, tooltipLabelAccessor: graphSessionsTooltipAccessor }),
        React.createElement(LineGraph, { labelAccessor: graphSessionsLabelAccessor, valueAccessors: graphSessionReliabilityValueAccessors, data: graphSessionsData, yAxisValueToLabel: function (v) { return "".concat(v.toFixed(2), "%"); }, manualOrder: true, tooltipLabelAccessor: graphSessionsTooltipAccessor }),
        React.createElement("hr", { className: "border-black-800 max-w-[50%]" }),
        React.createElement("label", { className: "font-N6 text-white mt-8" }, "Power, Energy, and Revenue"),
        React.createElement("div", { className: "flex flex-row flex-wrap gap-x-[16px]" },
            React.createElement(StatCard, { title: "Total Revenue", value: "$".concat(GetDisplayPrice(aggregate.totalRevenue, 2)) }),
            React.createElement(StatCard, { title: "Total Energy Delivered", value: "".concat(aggregate.totalEnergy.toFixed(2), " kWh") }),
            React.createElement(StatCard, { title: "Avg. Energy Delivered Per Session", value: "".concat(aggregate.averageEnergyKwh.toFixed(2), " kWh") }),
            React.createElement(StatCard, { title: "Avg. Peak Power Per Session", value: "".concat(aggregate.averagePeakPowerKw.toFixed(2), " kW") })),
        React.createElement("hr", { className: "border-black-800 max-w-[50%]" }),
        React.createElement("label", { className: "font-N6 text-white mt-8" }, "Customer Loyalty Data"),
        React.createElement("div", { className: "flex flex-row flex-wrap gap-x-[16px]" },
            React.createElement(StatCard, { title: "Total Sessions", value: "".concat(loyaltyTransactionStats.totalSessions) }),
            React.createElement(StatCard, { title: "Total Sessions By Repeat Customers", value: "".concat(loyaltyTransactionStats.totalSessionsByRepeatCustomers) }),
            React.createElement(StatCard, { title: "Unique Customers", value: "".concat(loyaltyTransactionStats.numberOfuniqueCustomers) }),
            React.createElement(StatCard, { title: "Total Repeat Customers", value: "".concat(loyaltyTransactionStats.numberOfRepeatCustomers) }),
            React.createElement(StatCard, { title: "Percentage of Repeat Customers", value: "".concat(Math.round((loyaltyTransactionStats.numberOfRepeatCustomers /
                    loyaltyTransactionStats.numberOfuniqueCustomers) *
                    10000) / 100, " %") }),
            React.createElement(StatCard, { title: "Percentage of Repeat Customer Sessions", value: "".concat(Math.round((loyaltyTransactionStats.totalSessionsByRepeatCustomers /
                    loyaltyTransactionStats.totalSessions) *
                    10000) / 100, " %") }),
            React.createElement("div", { className: "flex flex-col gap-y-2" },
                React.createElement(StaticTable, { data: loyaltyTransactionStats.top10EvccIds, columnDefinitions: loyaltyTableColumns, loading: false }))),
        React.createElement("hr", { className: "border-black-800 max-w-[50%]" }),
        React.createElement("label", { className: "font-N6 text-white mt-8" }, "Fault Code Breakdown"),
        aggregate.errorCodes.length > 0 && (React.createElement("div", { className: "flex flex-col gap-y-2" },
            React.createElement(StaticTable, { data: faultCodeTableData, columnDefinitions: faultCodeTableColumns, loading: false }))),
        React.createElement("hr", { className: "border-black-800 max-w-[50%]" }),
        React.createElement("label", { className: "font-N6 text-white mt-8 col-span-2" }, "ISO 15118 / Vehicle Manufacturer Coverage"),
        React.createElement("div", { className: "flex flex-row flex-wrap gap-x-[16px]" },
            React.createElement(StatCard, { title: "Total Market Share Coverage", value: "".concat(totalMarketShareTested.toFixed(1), "%") }),
            React.createElement(StatCard, { title: "Sessions with Unknown Mfg.", value: "".concat(((totalSessionsWithManufacturer / aggregate.totalSessions) *
                    100).toFixed(1), "%") })),
        React.createElement("div", { className: "flex flex-col gap-y-2" },
            React.createElement(StaticTable, { data: ccsManufacturerTableData, columnDefinitions: ccsManufacturerTableColumns, loading: false })),
        React.createElement("hr", { className: "border-black-800 max-w-[50%]" }),
        React.createElement("div", { className: "flex flex-col gap-y-2" },
            React.createElement("label", { className: "font-N6 text-white mt-8" }, "Payment Authorizations"),
            React.createElement("div", { className: "flex flex-row flex-wrap gap-x-[16px]" },
                React.createElement(StatCard, { title: "Card Present", value: "".concat((_b = aggregate.totalPaymentCardPresent) !== null && _b !== void 0 ? _b : 0) }),
                React.createElement(StatCard, { title: "Card Present (Offline)", value: "".concat((_c = aggregate.totalPaymentCardPresentOffline) !== null && _c !== void 0 ? _c : 0) }),
                React.createElement(StatCard, { title: "Phone IVR", value: "".concat((_d = aggregate.totalPaymentCardPresentPhone) !== null && _d !== void 0 ? _d : 0) }),
                React.createElement(StatCard, { title: "Command Console", value: "".concat((_e = aggregate.totalPaymentCommandConsole) !== null && _e !== void 0 ? _e : 0) }))),
        React.createElement("hr", { className: "border-black-800 max-w-[50%]" }),
        React.createElement("div", { className: "flex flex-col gap-y-2" },
            React.createElement("label", { className: "font-N6 text-white mt-8" }, "Feedback from Charger UI"),
            React.createElement("div", { className: "flex flex-row flex-wrap gap-x-[16px]" },
                React.createElement(StatCard, { title: "Average Rating", value: "".concat(averageFeedback ? averageFeedback.toFixed(1) : "--", " \u2606") }),
                React.createElement(StatCard, { title: "Sessions with Feedback", value: "".concat(((totalFeedback / aggregate.totalSessions) * 100).toFixed(1), "%") })),
            aggregate.feedback.feedbackBuckets
                .sort(function (a, b) { return b[0] - a[0]; })
                .map(function (b, i) {
                return (React.createElement("label", { className: "font-N7 text-white", key: i }, "".concat("\u2606".repeat(b[0]), " : ").concat(b[1], " reviews (").concat(Math.round((b[1] / totalFeedback) * 100), "%)")));
            }),
            aggregate.feedback.feedback.length > 0 && (React.createElement(React.Fragment, null,
                " ",
                React.createElement("label", { className: "font-N7 text-white mt-2" }, "Most recent feedback:"),
                aggregate.feedback.feedback.slice(0, 5).map(function (b, i) {
                    return (React.createElement("label", { className: "font-N8 text-white ml-2", key: i }, "\"".concat(b.message, "\" (").concat(b.rating, " stars, ").concat(new Date(b.date).toLocaleString(), ")")));
                }))))));
};
export var NocOrgStats = function (_a) {
    var _b, _c, _d;
    var organizationId = _a.organizationId;
    var _e = useAuth0(), getAccessTokenSilently = _e.getAccessTokenSilently, isLoading = _e.isLoading, isAuthenticated = _e.isAuthenticated;
    // Set up the time ranges we can select from in the radio selector.
    var TimeRangeSelectorOption;
    (function (TimeRangeSelectorOption) {
        TimeRangeSelectorOption[TimeRangeSelectorOption["OPTION_CUSTOM_RELATIVE_RANGE"] = 0] = "OPTION_CUSTOM_RELATIVE_RANGE";
        TimeRangeSelectorOption[TimeRangeSelectorOption["OPTION_CUSTOM_DATE_RANGE"] = 1] = "OPTION_CUSTOM_DATE_RANGE";
        TimeRangeSelectorOption[TimeRangeSelectorOption["OPTION_LAST_7_DAYS"] = 2] = "OPTION_LAST_7_DAYS";
        TimeRangeSelectorOption[TimeRangeSelectorOption["OPTION_LAST_30_DAYS"] = 3] = "OPTION_LAST_30_DAYS";
        TimeRangeSelectorOption[TimeRangeSelectorOption["OPTION_ALL_TIME"] = 4] = "OPTION_ALL_TIME";
    })(TimeRangeSelectorOption || (TimeRangeSelectorOption = {}));
    var radioItems = [
        {
            name: "Custom Relative Range",
            value: TimeRangeSelectorOption.OPTION_CUSTOM_RELATIVE_RANGE,
        },
        {
            name: "Custom Date Range",
            value: TimeRangeSelectorOption.OPTION_CUSTOM_DATE_RANGE,
        },
        {
            name: "Last 7 Days",
            value: TimeRangeSelectorOption.OPTION_LAST_7_DAYS,
        },
        {
            name: "Last 30 Days",
            value: TimeRangeSelectorOption.OPTION_LAST_30_DAYS,
        },
        {
            name: "All Time",
            value: TimeRangeSelectorOption.OPTION_ALL_TIME,
        },
    ].reverse();
    // State for the time range selector.
    var _f = useState(TimeRangeSelectorOption.OPTION_ALL_TIME), radioSelection = _f[0], setRadioSelection = _f[1];
    var relativeDayTextInput = useRef(null);
    var absoluteBeginInput = useRef(null);
    var absoluteEndInput = useRef(null);
    var movingAverageWindowInput = useRef(null);
    var searchParams = new URLSearchParams();
    var daysToMilliseconds = 1000 * 60 * 60 * 24;
    if (radioSelection == TimeRangeSelectorOption.OPTION_LAST_30_DAYS) {
        searchParams.append("fromNowRelativeMilliseconds", "".concat(30 * daysToMilliseconds));
    }
    else if (radioSelection == TimeRangeSelectorOption.OPTION_LAST_7_DAYS) {
        searchParams.append("fromNowRelativeMilliseconds", "".concat(7 * daysToMilliseconds));
    }
    else if (radioSelection == TimeRangeSelectorOption.OPTION_CUSTOM_RELATIVE_RANGE &&
        relativeDayTextInput.current) {
        var val = Number(relativeDayTextInput.current.value);
        if (val > 0) {
            searchParams.append("fromNowRelativeMilliseconds", "".concat(val * daysToMilliseconds));
        }
    }
    else if (radioSelection == TimeRangeSelectorOption.OPTION_CUSTOM_DATE_RANGE &&
        absoluteBeginInput.current &&
        absoluteEndInput.current) {
        if (absoluteBeginInput.current.value) {
            searchParams.append("from", new Date(absoluteBeginInput.current.value).toISOString());
        }
        if (absoluteEndInput.current.value) {
            searchParams.append("to", new Date(absoluteEndInput.current.value).toISOString());
        }
    }
    // Get the site list, for filtering by site.
    var siteList = useSWR(isLoading || !isAuthenticated
        ? null
        : [
            "".concat(process.env.CORE_SVC_URL, "/site/list/").concat(organizationId),
            getAccessTokenSilently,
        ], Auth0Fetcher).data;
    // Build the site list dropdown menu and state.
    var siteListDropdownMenu = siteList
        ? __spreadArray([
            { name: "All", value: "" }
        ], ((_b = siteList === null || siteList === void 0 ? void 0 : siteList.sites) !== null && _b !== void 0 ? _b : []).map(function (s) { return ({
            name: "".concat(s.id, ": ").concat(s.name),
            value: s.id,
        }); }), true) : [];
    var _g = useState([]), sitesMultiSelectState = _g[0], setSitesMultiSelectState = _g[1];
    if ((sitesMultiSelectState === null || sitesMultiSelectState === void 0 ? void 0 : sitesMultiSelectState.length) > 0) {
        sitesMultiSelectState.forEach(function (val) {
            return searchParams.append("siteIds", val);
        });
    }
    // Get the charger models, for filtering.
    var chargerModels = useSWR(isLoading || !isAuthenticated
        ? null
        : [
            "".concat(process.env.NOC_SVC_URL, "/chargers/charger_models"),
            getAccessTokenSilently,
        ], Auth0Fetcher).data;
    // Build the charger model dropdown menu and state.
    var chargerModelDropdownMenu = convertStringArrayToMultiSelectDropdownItems(chargerModels, true);
    var _h = useState([]), chargerModelsMultiSelectState = _h[0], setChargerModelsMultiSelectState = _h[1];
    if ((chargerModelsMultiSelectState === null || chargerModelsMultiSelectState === void 0 ? void 0 : chargerModelsMultiSelectState.length) > 0) {
        chargerModelsMultiSelectState.forEach(function (val) {
            return searchParams.append("chargerModels", val);
        });
    }
    // Get the charger models, for filtering.
    var swAndFwVersions = useSWR(isLoading || !isAuthenticated
        ? null
        : [
            "".concat(process.env.NOC_SVC_URL, "/organizations/sw_and_fw_versions"),
            getAccessTokenSilently,
        ], Auth0Fetcher).data;
    // Build the charger sw versions dropdown menu and state.
    var chargerSwVersionsDropdownMenu = convertStringArrayToMultiSelectDropdownItems(swAndFwVersions === null || swAndFwVersions === void 0 ? void 0 : swAndFwVersions.chargerSwVersions, true);
    var _j = useState([]), chargerSwVersionsMultiSelectState = _j[0], setChargerSwVersionsMultiSelectState = _j[1];
    if ((chargerSwVersionsMultiSelectState === null || chargerSwVersionsMultiSelectState === void 0 ? void 0 : chargerSwVersionsMultiSelectState.length) > 0) {
        chargerSwVersionsMultiSelectState.forEach(function (val) {
            return searchParams.append("chargerSwVersions", val);
        });
    }
    // Build the charger sw versions dropdown menu and state.
    var chargerFwVersionsDropdownMenu = convertStringArrayToMultiSelectDropdownItems(swAndFwVersions === null || swAndFwVersions === void 0 ? void 0 : swAndFwVersions.chargerFwVersions, true);
    var _k = useState([]), chargerFwVersionsMultiSelectState = _k[0], setChargerFwVersionsMultiSelectState = _k[1];
    if ((chargerFwVersionsMultiSelectState === null || chargerFwVersionsMultiSelectState === void 0 ? void 0 : chargerFwVersionsMultiSelectState.length) > 0) {
        chargerFwVersionsMultiSelectState.forEach(function (val) {
            return searchParams.append("chargerFwVersions", val);
        });
    }
    // Build the charger sw versions dropdown menu and state.
    var powerNodeSwVersionsDropdownMenu = convertStringArrayToMultiSelectDropdownItems(swAndFwVersions === null || swAndFwVersions === void 0 ? void 0 : swAndFwVersions.powerNodeSwVersions, true);
    var _l = useState([]), powerNodeSwVersionsMultiSelectState = _l[0], setPowerNodeSwVersionsMultiSelectState = _l[1];
    if ((powerNodeSwVersionsMultiSelectState === null || powerNodeSwVersionsMultiSelectState === void 0 ? void 0 : powerNodeSwVersionsMultiSelectState.length) > 0) {
        powerNodeSwVersionsMultiSelectState.forEach(function (val) {
            return searchParams.append("powerNodeSwVersions", val);
        });
    }
    // Get the transaction aggregate and timeseries statistics.
    var transactionStats = useSWR(isLoading || !isAuthenticated
        ? null
        : [
            "".concat(process.env.NOC_SVC_URL, "/organizations/stats?").concat(searchParams.toString()),
            getAccessTokenSilently,
        ], Auth0Fetcher).data;
    var loyaltyTransactionStats = useSWR(isLoading || !isAuthenticated
        ? null
        : [
            "".concat(process.env.NOC_SVC_URL, "/organizations/loyalty/stats?").concat(searchParams.toString()),
            getAccessTokenSilently,
        ], Auth0Fetcher).data;
    return (React.createElement("div", { className: "flex flex-col gap-y-[32px] mt-4" },
        React.createElement("div", { className: "flex flex-row gap-x-4 items-center" },
            React.createElement("label", { className: "font-N7 text-black-600" }, "Time Range"),
            React.createElement(Radio, { items: radioItems, value: radioSelection, setValue: setRadioSelection })),
        radioSelection == TimeRangeSelectorOption.OPTION_CUSTOM_DATE_RANGE && (React.createElement("div", { className: "flex flex-row gap-x-4 items-center" },
            React.createElement("label", { className: "font-N7 text-black-600" }, "Start Time"),
            React.createElement(TextInput, { type: "datetime-local", ref: absoluteBeginInput }),
            React.createElement("label", { className: "font-N7 text-black-600" }, "End Time"),
            React.createElement(TextInput, { type: "datetime-local", ref: absoluteEndInput }))),
        radioSelection ==
            TimeRangeSelectorOption.OPTION_CUSTOM_RELATIVE_RANGE && (React.createElement("div", { className: "flex flex-row gap-x-4 items-center" },
            React.createElement("label", { className: "font-N7 text-black-600" }, "Days Before Now"),
            React.createElement(TextInput, { type: "number", ref: relativeDayTextInput, defaultValue: 1 }))),
        React.createElement("div", { className: "flex flex-row gap-x-4 items-center" },
            React.createElement("label", { className: "font-N7 text-black-600" }, "Filter by Charger Model"),
            React.createElement(MultiSelectDropdown, { items: chargerModelDropdownMenu, currentSelections: chargerModelsMultiSelectState, onChange: setChargerModelsMultiSelectState }),
            React.createElement("label", { className: "font-N7 text-black-600" }, "Filter by Site"),
            React.createElement(MultiSelectDropdown, { items: siteListDropdownMenu, currentSelections: sitesMultiSelectState, onChange: setSitesMultiSelectState }),
            React.createElement("label", { className: "font-N7 text-black-600" }, "Averaging Window (Days)"),
            React.createElement(TextInput, { type: "number", ref: movingAverageWindowInput, defaultValue: 30 })),
        React.createElement(Disclosure, null, function (_a) {
            var open = _a.open;
            return (React.createElement(React.Fragment, null,
                React.createElement(Disclosure.Button, { className: "bg-black-1000 hover:bg-black-800 rounded-[10px] px-[16px] py-[8px] flex flex-row gap-x-[32px] w-3/5 justify-between" },
                    React.createElement("label", { className: "font-N6" }, "Additional Filters"),
                    open ? (React.createElement(ChevronDownIcon, { className: "w-[16px] h-[16px]" })) : (React.createElement(ChevronUpIcon, { className: "w-[16px] h-[16px]" }))),
                React.createElement(Disclosure.Panel, { className: "w-full" },
                    React.createElement("div", { className: "flex flex-row gap-x-4 items-center" },
                        React.createElement("label", { className: "font-N7 text-black-600" }, "Filter by Charger Software Version"),
                        React.createElement(MultiSelectDropdown, { items: chargerSwVersionsDropdownMenu, currentSelections: chargerSwVersionsMultiSelectState, onChange: setChargerSwVersionsMultiSelectState }),
                        React.createElement("label", { className: "font-N7 text-black-600" }, "Filter by Charger Firmware Version"),
                        React.createElement(MultiSelectDropdown, { items: chargerFwVersionsDropdownMenu, currentSelections: chargerFwVersionsMultiSelectState, onChange: setChargerFwVersionsMultiSelectState })),
                    React.createElement("div", { className: "flex flex-row gap-x-4 mt-4 items-center" },
                        React.createElement("label", { className: "font-N7 text-black-600" }, "Filter by Power Node Software Version"),
                        React.createElement(MultiSelectDropdown, { items: powerNodeSwVersionsDropdownMenu, currentSelections: powerNodeSwVersionsMultiSelectState, onChange: setPowerNodeSwVersionsMultiSelectState })))));
        }),
        React.createElement("hr", { className: "border-black-800 max-w-[50%]" }),
        transactionStats && loyaltyTransactionStats && (React.createElement(React.Fragment, null,
            React.createElement(StatsBreakdown, { stats: transactionStats, loyaltyStats: loyaltyTransactionStats, movingAverageWindowSizeDays: Math.round(Number((_d = (_c = movingAverageWindowInput.current) === null || _c === void 0 ? void 0 : _c.value) !== null && _d !== void 0 ? _d : "30")) }),
            React.createElement("hr", { className: "border-black-800 max-w-[50%]" })))));
};
