var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { process } from "../../Process";
import { PaginatedTable } from "../Table";
export var SiteAnalysisResultsTable = function (_a) {
    var resourceUrl = _a.resourceUrl, onClickRow = _a.onClickRow, maxRows = _a.maxRows;
    var rowsFromResponse = function (response) { return response.details; };
    var rowCountFromResponse = function (response) { return response.pages; };
    var canClickRow = function (row) { return row.isComplete; };
    var columns = [
        {
            header: "ID",
            accessorFn: function (row) {
                return "".concat(row.id);
            },
        },
        {
            header: "Username",
            accessorFn: function (row) {
                return row.initiatingUsername;
            },
        },
        {
            header: "Sites Processed",
            accessorFn: function (row) {
                return row.sitesProcessed;
            },
        },
        {
            header: "Total Site Count",
            accessorFn: function (row) {
                return row.totalSites;
            },
        },
        {
            header: "Status",
            accessorFn: function (row) {
                return row.isComplete ? "Complete" : "Incomplete";
            },
        },
    ];
    return (React.createElement(PaginatedTable, { baseUrl: resourceUrl, itemsPerPage: maxRows !== null && maxRows !== void 0 ? maxRows : 10, rowsFromResponse: rowsFromResponse, rowCountFromResponse: rowCountFromResponse, columnDefinitions: columns, onClickRow: onClickRow, canClickRow: canClickRow }));
};
export var SiteSelection = function () {
    var _a = useAuth0(), user = _a.user, isAuthenticated = _a.isAuthenticated, isLoading = _a.isLoading, getAccessTokenSilently = _a.getAccessTokenSilently;
    var _b = React.useState(null), batchFile = _b[0], setBatchFile = _b[1];
    var batchFileRef = React.useRef();
    var _c = React.useState(false), wasError = _c[0], setWasError = _c[1];
    var _d = React.useState(""), errorString = _d[0], setErrorString = _d[1];
    var _e = React.useState(false), sendBatchDisabled = _e[0], setSendBatchDisabled = _e[1];
    var handleFileChange = function (e) {
        setBatchFile(e.target.files[0]);
    };
    var handleSendJob = function () { return __awaiter(void 0, void 0, void 0, function () {
        var accessToken, formData, response, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setWasError(false);
                    setErrorString("");
                    setSendBatchDisabled(true);
                    if (!batchFile)
                        return [2 /*return*/];
                    return [4 /*yield*/, getAccessTokenSilently({
                            authorizationParams: {
                                audience: process.env.AUTH0_AUDIENCE,
                            },
                        })];
                case 1:
                    accessToken = _a.sent();
                    formData = new FormData();
                    formData.append("file", batchFile);
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, , 7, 8]);
                    return [4 /*yield*/, fetch("".concat(process.env.PROSPECTOR_URL, "/site-selection/v2/batch?initiatingUsername=").concat(user === null || user === void 0 ? void 0 : user.email), {
                            method: "POST",
                            body: formData,
                            headers: {
                                authorization: "Bearer ".concat(accessToken),
                            },
                        })];
                case 3:
                    response = _a.sent();
                    if (!(response.status !== 201)) return [3 /*break*/, 5];
                    return [4 /*yield*/, response.json()];
                case 4:
                    res = _a.sent();
                    console.error(response.status);
                    console.error(res);
                    setWasError(true);
                    setErrorString(res.message);
                    return [3 /*break*/, 6];
                case 5:
                    batchFileRef.current.value = "";
                    _a.label = 6;
                case 6: return [3 /*break*/, 8];
                case 7:
                    setSendBatchDisabled(false);
                    return [7 /*endfinally*/];
                case 8: return [2 /*return*/];
            }
        });
    }); };
    var downloadAnalysisResults = function (row) { return __awaiter(void 0, void 0, void 0, function () {
        var accessToken;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAccessTokenSilently({
                        authorizationParams: {
                            audience: process.env.AUTH0_AUDIENCE,
                        },
                    })];
                case 1:
                    accessToken = _a.sent();
                    void fetch("".concat(process.env.PROSPECTOR_URL, "/site-selection/results/").concat(row.id), {
                        headers: {
                            authorization: "Bearer ".concat(accessToken),
                        },
                    })
                        .then(function (response) { return response.blob(); })
                        .then(function (blob) {
                        var url = window.URL.createObjectURL(blob);
                        window.open(url, "_blank").focus();
                    });
                    return [2 /*return*/];
            }
        });
    }); };
    if (!isAuthenticated || isLoading)
        return null;
    return (React.createElement("div", null,
        React.createElement("input", { type: "file", accept: ".xlsx", onChange: handleFileChange, ref: batchFileRef }),
        React.createElement("button", { onClick: handleSendJob, className: "".concat(sendBatchDisabled ? "bg-gray" : "bg-red", " text-white w-fit px-[32px] py-[16px] rounded-[10px]"), disabled: sendBatchDisabled }, "Send Batch Job"),
        wasError && React.createElement("div", null,
            "There was an error sending the job: ",
            errorString),
        React.createElement(SiteAnalysisResultsTable, { onClickRow: downloadAnalysisResults, resourceUrl: "".concat(process.env.PROSPECTOR_URL, "/site-selection/results"), maxRows: 20 })));
};
