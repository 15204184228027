var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useRef, useState } from "react";
import { events_svc } from "ee-proto-interfaces";
import { charger } from "ee-proto-interfaces";
import { SingleStat } from "./SingleStat";
import { ComparisonStat } from "./ComparisonStat";
import { Battery, BatteryBank, BatteryBankWithGradient, Car, CarWithGradient, EVStation, PowernodeBattery, } from "./Images";
import { GetCarInformationFromEVCCID, GetDisplayPrice, PortTypeToString, } from "ee-utils";
import { RadialGraph } from "./Graph/RadialGraph";
import { ComparativeBarGraph } from "./Graph/BarGraph";
import { StyledButton, StyledButtonStyle } from "./StyledButton";
import { Dialog, Transition } from "@headlessui/react";
import { RelatedTransactionsTable } from "../Pages/Transaction";
import { TextInput } from "./Input";
import { SuccessIndicator } from "./SuccessIndicator";
import { useAuth0 } from "@auth0/auth0-react";
import { process } from "../Process";
import { LoadingIcon } from "ee-tailwind-components";
export var CardColors;
(function (CardColors) {
    CardColors[CardColors["GRADIENT"] = 0] = "GRADIENT";
    CardColors[CardColors["GREY"] = 1] = "GREY";
})(CardColors || (CardColors = {}));
var CardBadge = function (_a) {
    var label = _a.label, colors = _a.colors, className = _a.className;
    var background = "";
    switch (colors) {
        case CardColors.GRADIENT:
            background =
                "bg-gradient-to-r from-main-gradient-from to-main-gradient-to";
            break;
        case CardColors.GREY:
            background = "bg-black-800";
            break;
    }
    return (React.createElement("div", { className: "w-fit rounded-[10px] px-[16px] py-[8px] ".concat(background, " ").concat(className !== null && className !== void 0 ? className : "") },
        React.createElement("label", { className: "text-white font-N10" }, label)));
};
export var Card = function (_a) {
    var className = _a.className, wrapperClassName = _a.wrapperClassName, children = _a.children, noPadding = _a.noPadding, spotlights = _a.spotlights, largeSpotlights = _a.largeSpotlights;
    return (React.createElement("div", { className: "grid rounded-[10px] overflow-clip ".concat(wrapperClassName !== null && wrapperClassName !== void 0 ? wrapperClassName : "") },
        React.createElement("div", { className: "overlap-grid w-full h-full overflow-hidden relative z-[-50]" },
            spotlights.map(function (light, idx) {
                return (React.createElement("div", { key: idx, className: "absolute ".concat(light, " bg-radial-spotlight w-[600px] h-[600px]") }));
            }),
            largeSpotlights != null &&
                largeSpotlights.map(function (light, idx) {
                    return (React.createElement("div", { key: idx, className: "absolute ".concat(light, " bg-radial-spotlight-lg w-[600px] h-[600px]") }));
                })),
        React.createElement("div", { className: "".concat(noPadding ? "" : "p-[16px]", " ").concat(className !== null && className !== void 0 ? className : "", " overlap-grid w-full") }, children)));
};
export var StatCard = function (_a) {
    var title = _a.title, value = _a.value, children = _a.children;
    return (React.createElement(Card, { className: "flex flex-row w-[300px] items-center", spotlights: ["top-[-360px] right-[-230px]", "top-[-150px] left-[-250px]"] },
        React.createElement(SingleStat, { title: title, value: value }),
        React.createElement("div", { className: "grow" }),
        children));
};
export var ComparisonStatCard = function (_a) {
    var header = _a.header, title = _a.title, firstValue = _a.firstValue, secondValue = _a.secondValue, isTimeInSeconds = _a.isTimeInSeconds, prefix = _a.prefix, suffix = _a.suffix, numberFormatOptions = _a.numberFormatOptions;
    return (React.createElement(Card, { className: "flex flex-row w-[450px] items-center", spotlights: ["top-[-360px] right-[-230px]", "top-[-150px] left-[-250px]"] },
        React.createElement(ComparisonStat, { header: header, title: title, firstValue: firstValue, secondValue: secondValue, isTimeInSeconds: isTimeInSeconds, prefix: prefix, suffix: suffix, numberFormatOptions: numberFormatOptions })));
};
export var RadialStatCard = function (_a) {
    var title = _a.title, value = _a.value, valueToString = _a.valueToString;
    var conversion = valueToString !== null && valueToString !== void 0 ? valueToString : (function (val) { return "".concat((val * 100).toFixed(1)); });
    var renderedValue = value === 1 ? "100%" : value ? "".concat(conversion(value), "%") : "--";
    return (React.createElement(StatCard, { title: title, value: renderedValue }, value != null && (React.createElement(RadialGraph, { title: title, value: value, className: "h-[42px] w-[42px]" }))));
};
export var BarStatCard = function (_a) {
    var title = _a.title, valueToString = _a.valueToString, primaryValue = _a.primaryValue, historicalValues = _a.historicalValues;
    return (React.createElement(StatCard, { title: title, value: primaryValue != null ? valueToString(primaryValue) : "--" }, historicalValues != null && (React.createElement(ComparativeBarGraph, { values: historicalValues }))));
};
export var PowernodeCard = function (_a) {
    var id = _a.id, soc = _a.soc, chargeRate = _a.chargeRate, batteryStatus = _a.batteryStatus, powernodeStatus = _a.powernodeStatus, anchorId = _a.anchorId;
    var colors;
    var badgeTitle = "";
    var chargeRateLabel = "".concat(Number(-chargeRate).toFixed(2), " kW");
    var primaryTextColor = "text-white";
    var iconFillColor = "fill-white";
    var idle = false;
    switch (batteryStatus) {
        case events_svc.BatteryStatus.CHARGING:
            badgeTitle = "CHARGING BATTERY";
            colors = CardColors.GRADIENT;
            break;
        case events_svc.BatteryStatus.DISCHARGING:
            badgeTitle = "DISCHARGING BATTERY";
            colors = CardColors.GRADIENT;
            break;
        case events_svc.BatteryStatus.IDLE:
        default:
            badgeTitle = "IDLE";
            colors = CardColors.GREY;
            chargeRateLabel = "--";
            primaryTextColor = "text-black-800";
            iconFillColor = "fill-black-800";
            idle = true;
            break;
    }
    var powernodeTitle;
    switch (powernodeStatus) {
        case events_svc.PowernodeStatus.ONLINE:
            powernodeTitle = "Battery Online";
            break;
        case events_svc.PowernodeStatus.MAINTENANCE:
            powernodeTitle = "Battery Offline";
            break;
        default:
            powernodeTitle = "Battery Unrecognized";
    }
    var fillRef = useRef(null);
    useEffect(function () {
        if (fillRef.current) {
            var percent = 100 - soc;
            percent = Math.max(0, Math.min(100, Math.round(percent)));
            var percentageString = "".concat(percent, "%");
            fillRef.current.style.clipPath = "polygon(0% ".concat(percentageString, ", 100% ").concat(percentageString, ", 100% 100%, 0% 100%)");
        }
    });
    return (React.createElement(CardWithTitle, { title: "Powernode: ".concat(powernodeStatus.toString()), secondary: "ID: ".concat(id), icon: PowernodeBattery },
        React.createElement("div", { className: "flex flex-col items-center" },
            React.createElement("div", { id: anchorId }),
            React.createElement(GradientBorderCard, { colors: colors },
                React.createElement("div", { className: "flex flex-col gap-y-[32px] items-center py-[8px] px-[16px]" },
                    React.createElement("label", { className: "font-N8p5 text-white h-0 translate-y-[-20px]" }, powernodeTitle),
                    React.createElement("div", { className: "grid grid-cols-3 place-content-center gap-x-[16px] items-center" },
                        React.createElement(SingleStat, { title: "Level", value: "".concat(Number(soc).toFixed(1), "%"), center: true, large: true, titleClassName: "".concat(primaryTextColor, " px-[32px]"), valueClassName: primaryTextColor, monospace: true }),
                        React.createElement("div", { className: "flex flex-row items-center justify-center" },
                            React.createElement("div", { className: "grid" },
                                React.createElement("div", { className: "overlap-grid" }, BatteryBank(iconFillColor)),
                                React.createElement("div", { className: "overlap-grid ".concat(!idle ? "" : "opacity-0"), ref: fillRef }, BatteryBankWithGradient(iconFillColor)))),
                        React.createElement(SingleStat, { title: "Power", value: chargeRateLabel, center: true, large: true, titleClassName: "".concat(primaryTextColor, " px-[32px]"), valueClassName: primaryTextColor, monospace: true })),
                    React.createElement(CardBadge, { colors: colors, label: badgeTitle }))))));
};
var PortAuthorizePayment = function (_a) {
    var chargerFriendlyId = _a.chargerFriendlyId, portId = _a.portId, portType = _a.portType, ocppId = _a.ocppId, close = _a.close, onSuccess = _a.onSuccess;
    var getAccessTokenSilently = useAuth0().getAccessTokenSilently;
    var _b = useState(), submitErrorMessage = _b[0], setSubmitErrorMessage = _b[1];
    var _c = useState(false), submitting = _c[0], setSubmitting = _c[1];
    var _d = useState(""), authorizedAmount = _d[0], setAuthorizedAmount = _d[1];
    var displayErrorMessage = function (message) {
        setSubmitErrorMessage(message);
        setTimeout(function () {
            setSubmitErrorMessage(undefined);
        }, 10000);
    };
    var onSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var parsedAmount, authorizePayment, accessToken, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (authorizedAmount == "") {
                        displayErrorMessage("Please enter a maximum amount to authorize.");
                    }
                    parsedAmount = Math.round(parseFloat(authorizedAmount) * 100);
                    if (isNaN(parsedAmount)) {
                        displayErrorMessage("Please enter a valid amount to authorize.");
                        return [2 /*return*/];
                    }
                    setSubmitting(true);
                    authorizePayment = {
                        portId: portId,
                        amount: {
                            amount: parsedAmount,
                            precision: 2,
                            currencyCode: "USD",
                        },
                    };
                    return [4 /*yield*/, getAccessTokenSilently({
                            authorizationParams: {
                                audience: process.env.AUTH0_AUDIENCE,
                            },
                        })];
                case 1:
                    accessToken = _a.sent();
                    return [4 /*yield*/, fetch("".concat(process.env.CORE_SVC_URL, "/pricing/authorize/").concat(ocppId), {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                authorization: "Bearer ".concat(accessToken),
                            },
                            body: JSON.stringify(authorizePayment),
                        })];
                case 2:
                    res = _a.sent();
                    if (!res.ok) {
                        displayErrorMessage("Something went wrong while submitting authorization. Please try again.");
                        setSubmitting(false);
                    }
                    else {
                        onSuccess();
                        close();
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement("div", { className: "bg-black-1150 rounded-[20px] p-[32px] flex flex-col gap-y-[48px] min-w-[650px] max-w-[900px]" },
        React.createElement("label", { className: "font-N5 text-white w-full text-center" }, "Authorize Payment"),
        React.createElement("label", { className: "font-N7 text-white" }, "Authorizing a payment allows for a customer to charge their car without requiring payment at the charger's payment terminal. Once the session is complete, the session's details will be available on the \"Transactions\" tab. Authorizations are valid for two minutes."),
        React.createElement("div", { className: "flex flex-col gap-y-[12px]" },
            React.createElement("label", { className: "font-N8 text-white" }, "Please verify the following information:"),
            React.createElement("div", { className: "grid grid-cols-2 gap-x-8 gap-y-2 w-fit" },
                React.createElement("label", { className: "font-N8" }, "Charger ID"),
                React.createElement("label", { className: "font-N8p5" }, chargerFriendlyId),
                React.createElement("label", { className: "font-N8" }, "Port ID"),
                React.createElement("label", { className: "font-N8p5" }, "".concat(portId)),
                React.createElement("label", { className: "font-N8" }, "Port Type"),
                React.createElement("label", { className: "font-N8p5" }, "".concat(portType)))),
        React.createElement("div", { className: "flex flex-col gap-y-[8px]" },
            React.createElement("label", { className: "text-black-400 font-N8p5" }, "MAXIMUM AMOUNT AUTHORIZED"),
            React.createElement("div", { className: "flex flex-row gap-x-[32px]" },
                React.createElement("span", { className: "before:text-black-600 relative before:content-['$'] before:absolute before:pl-[8px] before:translate-y-[-50%] before:top-[50%]" },
                    React.createElement(TextInput, { placeholder: "ENTER AMOUNT", type: "number", step: ".01", min: ".01", max: "100", pattern: "^\\d+(\\.)\\d{2}$", className: "w-[200px]", onChange: function (e) { return setAuthorizedAmount(e.target.value); } })))),
        React.createElement("div", { className: "flex flex-row gap-x-4" },
            React.createElement(StyledButton, { text: "SUBMIT", onClick: onSubmit, className: "mt-12", disabled: submitting }),
            React.createElement(StyledButton, { text: "CANCEL", style: StyledButtonStyle.Gray, onClick: close, className: "mt-12" })),
        React.createElement(SuccessIndicator, { message: submitErrorMessage, success: false })));
};
var PortInformation = function (_a) {
    var telemetry = _a.telemetry, isNoc = _a.isNoc, pricingModel = _a.pricingModel, unavailableReason = _a.unavailableReason, bothPortsInUse = _a.bothPortsInUse, chargerFriendlyId = _a.chargerFriendlyId, chargerOcppId = _a.chargerOcppId, portId = _a.portId, allowRemoteAuthorization = _a.allowRemoteAuthorization;
    var activeState = false;
    var stateLabel = telemetry.ocppStatus.toUpperCase();
    var inUse = false;
    var colors = CardColors.GREY;
    var primaryTextColor = "text-black-800";
    var iconFillColor = "fill-black-800";
    switch (stateLabel) {
        case "CHARGING":
            iconFillColor = "fill-white";
            inUse = true;
            activeState = true;
            stateLabel = "CHARGING";
            colors = CardColors.GRADIENT;
            break;
        case "PREPARING":
            activeState = true;
            if (telemetry.transactionStarted || telemetry.paymentReady) {
                stateLabel = "BEGINNING SESSION";
            }
            else {
                stateLabel = "PLUGGED IN. WAITING FOR PAYMENT";
            }
            colors = CardColors.GRADIENT;
            break;
        case "FINISHING":
            activeState = true;
            stateLabel = "WAITING FOR CAR TO UNPLUG";
            colors = CardColors.GRADIENT;
            break;
        case "AVAILABLE":
            stateLabel = telemetry.paymentReady
                ? "PAYMENT READY. WAITING FOR CAR"
                : "IDLE";
            activeState = telemetry.paymentReady;
            break;
        case "UNAVAILABLE":
        /* falls through */
        case "FAULTED":
        /* falls through */
        default:
            break;
    }
    if (activeState) {
        primaryTextColor = "text-white";
    }
    var portName = PortTypeToString(telemetry.portType);
    var fillRef = useRef(null);
    useEffect(function () {
        if (fillRef.current) {
            var percent = telemetry.carSoC;
            var innerPercentage = Math.max(0, Math.min(100, Math.round(percent)));
            var outerPercentage = innerPercentage - 8;
            var innerPercentString = "".concat(innerPercentage, "%");
            var outerPercentString = "".concat(outerPercentage, "%");
            var carBottomHeightPercentage = "20%";
            var carTopHeightPercentage = "80%";
            fillRef.current.style.clipPath = "polygon(".concat(outerPercentString, " 100%, ").concat(innerPercentString, " ").concat(carTopHeightPercentage, ", ").concat(innerPercentString, " ").concat(carBottomHeightPercentage, ", ").concat(outerPercentString, " 0%, 0 0, 0 100%)");
        }
    });
    var opacityTransitionProperties = {
        enter: "transition-opacity duration-500 pointer-events-none",
        enterFrom: "opacity-0",
        enterTo: "opacity-100",
        leave: "transition-opacity duration-300 pointer-events-none",
        leaveFrom: "opacity-100",
        leaveTo: "opacity-0",
    };
    var _b = useState(false), showRelated = _b[0], setShowRelated = _b[1];
    var secondsToGraphDisplayTime = function (seconds) {
        seconds = Math.round(seconds);
        var chargeSeconds = seconds % 60;
        var chargeMinutes = Math.floor(seconds / 60);
        return "".concat(chargeMinutes, "m ").concat(chargeSeconds, "s");
    };
    // Color telemetry staleness based on > 30 seconds out of contact.
    var telemetryUpdateTime = new Date(telemetry.updatedTime);
    var telemetryStale = (new Date().getTime() - telemetryUpdateTime.getTime()) / 1000 > 30;
    var telemetryVeryStale = (new Date().getTime() - telemetryUpdateTime.getTime()) / 1000 > 60 * 2;
    var available = !telemetryVeryStale &&
        (unavailableReason == null ||
            unavailableReason == charger.UnavailableReason.NONE);
    var _c = useState(false), showAuthorizePaymentDialog = _c[0], setShowAuthorizePaymentDialog = _c[1];
    var _d = useState(false), showAuthorizePaymentLoading = _d[0], setShowAuthorizePaymentLoading = _d[1];
    var showAuthorizePaymentLoadingTemporarily = function () {
        setShowAuthorizePaymentLoading(true);
        setTimeout(function () { return setShowAuthorizePaymentLoading(false); }, 15 * 1000);
    };
    var carInformation = telemetry.evccid != null && telemetry.evccid != ""
        ? GetCarInformationFromEVCCID(telemetry.evccid)
        : undefined;
    return (React.createElement(GradientBorderCard, { colors: colors },
        React.createElement("div", { className: "flex flex-col items-center gap-y-[12px]" },
            React.createElement("div", { className: "grid" },
                React.createElement("div", { className: "overlap-grid" }, Car(iconFillColor)),
                React.createElement("div", { className: "overlap-grid ".concat(inUse ? "" : "opacity-0"), ref: fillRef }, CarWithGradient(iconFillColor))),
            React.createElement("div", { className: "flex flex-row items-center gap-x-[8px]" },
                Battery(iconFillColor),
                React.createElement("label", { className: "font-N10 ".concat(primaryTextColor) }, inUse ? "".concat(telemetry.carSoC, "%") : "--")),
            React.createElement("div", { className: "flex flex-row items-center gap-x-[16px]" },
                React.createElement("label", { className: "font-N8 text-white" }, portName),
                React.createElement("label", { className: "font-N8p5 text-black-400" }, "".concat(telemetry.totalSessions, " session").concat(telemetry.totalSessions != 1 ? "s" : ""))),
            React.createElement(CardBadge, { label: stateLabel, colors: colors, className: "my-[32px]" }),
            React.createElement("div", { className: "grid grid-cols-2 justify-between gap-x-[16px] gap-y-[16px]" },
                React.createElement(SingleStat, { title: "Charge Rate", value: "".concat(inUse ? "".concat(Math.round(Number(telemetry.powerKw))) : "--", " kW"), center: true, valueClassName: primaryTextColor, monospace: true }),
                React.createElement(SingleStat, { title: bothPortsInUse ? "Shared Limit" : "Limit", value: "".concat(inUse ? "".concat(Math.round(Number(telemetry.powerLimitKw))) : "--", " kW"), center: true, valueClassName: primaryTextColor, monospace: true }),
                React.createElement(SingleStat, { title: "Car Request", value: "".concat(inUse ? "".concat(Math.round(Number(telemetry.powerRequestedKw))) : "--", " kW"), center: true, valueClassName: primaryTextColor, monospace: true }),
                React.createElement(SingleStat, { title: "Energy Delivered", value: "".concat(inUse ? "".concat(Number(telemetry.energyDelivered).toFixed(1)) : "--", " kWh"), center: true, valueClassName: primaryTextColor, monospace: true })),
            pricingModel != null &&
                pricingModel.pricePerKwh != null &&
                pricingModel.pricePerKwh.amount != null && (React.createElement(SingleStat, { title: "Estimated Sale", value: "".concat(inUse
                    ? "$".concat(GetDisplayPrice(Math.round(pricingModel.pricePerKwh.amount.amount *
                        telemetry.energyDelivered), pricingModel.pricePerKwh.amount.precision))
                    : "--"), center: true, valueClassName: primaryTextColor, className: "mt-[32px]", monospace: true })),
            isNoc && (React.createElement(React.Fragment, null,
                React.createElement("hr", { className: "border-black-800 max-w-[55%] my-2 w-full" }),
                React.createElement("label", { className: "font-N8 text-black-400" }, "OCPP Status: ".concat(telemetry.ocppStatus)),
                React.createElement("label", { className: "font-N8 text-black-400" }, "Port ID: ".concat(portId)),
                telemetry.transactionStartTime != null && (React.createElement("label", { className: "font-N8 text-black-400" }, "Duration: ".concat(secondsToGraphDisplayTime((new Date().getTime() -
                    new Date(telemetry.transactionStartTime).getTime()) /
                    1000)))),
                telemetry.evccid != null && telemetry.evccid != "" && (React.createElement("div", { className: "flex flex-col gap-y-2 mt-3 mb-3 items-center" },
                    React.createElement("label", { className: "font-N8 text-black-400" }, "EVCCID: ".concat(telemetry.evccid)),
                    React.createElement("label", { className: "font-N8 ".concat(carInformation ? "text-black-400" : "text-caution-yellow") }, "CCS Mfr.: ".concat(carInformation
                        ? carInformation.manufacturer
                        : "Unknown Manufacturer")),
                    (carInformation === null || carInformation === void 0 ? void 0 : carInformation.suspectedCarManufacturers) != null && (React.createElement("label", { className: "font-N8 text-black-400" }, "Suspected Car Mfr.: ".concat(carInformation.suspectedCarManufacturers.join(", ")))),
                    (carInformation === null || carInformation === void 0 ? void 0 : carInformation.confirmedCarManufacturers) != null && (React.createElement("label", { className: "font-N8 text-black-400" }, "Confirmed Mfr.: ".concat(carInformation.confirmedCarManufacturers.join(", ")))),
                    (carInformation === null || carInformation === void 0 ? void 0 : carInformation.confirmedCars) != null && (React.createElement("label", { className: "font-N8 text-black-400" }, "Confirmed Cars: ".concat(carInformation.confirmedCars.join(", ")))),
                    React.createElement("label", { className: "font-N8 text-black-400" }, "".concat(telemetry.evccidRelatedTransactions.length, " previous transactions")),
                    telemetry.evccidRelatedTransactions.length > 0 && (React.createElement(StyledButton, { style: StyledButtonStyle.Gray, text: showRelated ? "HIDE RELATED" : "SHOW RELATED", onClick: function () { return setShowRelated(!showRelated); } })),
                    React.createElement(Transition, __assign({ className: "absolute rounded-[10px] bg-black-1200 border-[1px] border-white font-N8 text-white whitespace-normal p-4" }, opacityTransitionProperties, { show: showRelated }),
                        React.createElement(StyledButton, { style: StyledButtonStyle.Gray, text: "CLOSE", onClick: function () { return setShowRelated(false); } }),
                        React.createElement(RelatedTransactionsTable, { transactions: telemetry.evccidRelatedTransactions, title: "Transactions With Same EVCCID" })))),
                telemetry.faultCode != null && telemetry.faultCode != "" && (React.createElement("label", { className: "font-N8 text-caution-yellow" }, "Fault Code: ".concat(telemetry.faultCode))),
                React.createElement("label", { className: "font-N8 ".concat(telemetryStale ? "text-caution-yellow" : "text-black-400") }, "Last updated:"),
                React.createElement("label", { className: "font-N8 ".concat(telemetryStale ? "text-caution-yellow" : "text-black-400") }, "".concat(telemetryUpdateTime.toLocaleString())),
                unavailableReason != null &&
                    unavailableReason != charger.UnavailableReason.NONE && (React.createElement(React.Fragment, null,
                    React.createElement("label", { className: "text-caution-yellow font-N8" }, "Unavailable Reason:"),
                    React.createElement("label", { className: "text-caution-yellow font-N8" }, "".concat(charger.UnavailableReason[unavailableReason])))))),
            allowRemoteAuthorization &&
                !telemetry.paymentReady &&
                !telemetry.transactionStarted && (React.createElement(React.Fragment, null,
                React.createElement("hr", { className: "border-black-800 max-w-[55%] my-2 w-full" }),
                !showAuthorizePaymentLoading && (React.createElement(StyledButton, { text: available ? "AUTHORIZE PAYMENT" : "CHARGER NOT READY", onClick: function () { return setShowAuthorizePaymentDialog(true); }, disabled: !available })),
                showAuthorizePaymentLoading && (React.createElement("div", { className: "w-[32px] h-[32px]" }, LoadingIcon)),
                React.createElement(Dialog, { open: showAuthorizePaymentDialog, onClose: function () { return setShowAuthorizePaymentDialog(false); }, className: "relative z-50" },
                    React.createElement("div", { className: "fixed inset-0 bg-black/30", "aria-hidden": "true" }),
                    React.createElement("div", { className: "fixed inset-0 flex items-center justify-center p-4" },
                        React.createElement(Dialog.Panel, { className: "mx-auto" },
                            React.createElement(PortAuthorizePayment, { chargerFriendlyId: chargerFriendlyId, portId: portId, portType: portName, close: function () { return setShowAuthorizePaymentDialog(false); }, ocppId: chargerOcppId, onSuccess: showAuthorizePaymentLoadingTemporarily })))))))));
};
export var GradientBorderCard = function (_a) {
    var children = _a.children, colors = _a.colors, outerClassName = _a.outerClassName, noPad = _a.noPad;
    return (React.createElement("div", { className: "rounded-[20px] p-[2px] ".concat(colors == CardColors.GRADIENT
            ? "bg-gradient-to-r from-main-gradient-from to-main-gradient-to"
            : "bg-black-800", " ").concat(outerClassName !== null && outerClassName !== void 0 ? outerClassName : "") },
        React.createElement("div", { className: "h-full ".concat(colors == CardColors.GRADIENT ? "bg-black-1200/90" : "bg-black-1200", " rounded-[20px] ").concat(noPad ? "" : "p-[32px]") }, children)));
};
export var CardWithTitle = function (_a) {
    var children = _a.children, icon = _a.icon, title = _a.title, secondary = _a.secondary, className = _a.className;
    return (React.createElement("div", { className: "".concat(className !== null && className !== void 0 ? className : "", " flex flex-col gap-y-[32px]") },
        React.createElement("div", { className: "flex flex-row gap-x-[32px] ml-[32px]" },
            icon,
            React.createElement("div", { className: "flex flex-col justify-between" },
                React.createElement("label", { className: "font-N7 text-white" }, title),
                React.createElement("label", { className: "font-N8p5 text-white" }, secondary))),
        children));
};
export var ChargerCard = function (_a) {
    var id = _a.id, ocppId = _a.ocppId, isNoc = _a.isNoc, port1 = _a.port1, port2 = _a.port2, pricingModel = _a.pricingModel, unavailableReason = _a.unavailableReason, anchorId = _a.anchorId, allowRemoteAuthorization = _a.allowRemoteAuthorization;
    var bothPortsInUse = (port1 === null || port1 === void 0 ? void 0 : port1.ocppStatus.toUpperCase()) == "CHARGING" &&
        (port2 === null || port2 === void 0 ? void 0 : port2.ocppStatus.toUpperCase()) == "CHARGING";
    // Explicit width is given to prevent reflows and performance issues :(
    return (React.createElement(CardWithTitle, { title: "Charger", secondary: "ID: ".concat(id), icon: EVStation, className: "w-[600px]" },
        React.createElement("div", { className: "flex flex-col items-center" },
            React.createElement("div", { id: anchorId }),
            isNoc && (React.createElement("label", { className: "font-N8p5 text-white h-0 translate-y-[-20px]" }, "OCPP ID: ".concat(ocppId))),
            React.createElement("div", { className: "flex flex-row gap-x-[8px]" },
                port1 != null && (React.createElement(PortInformation, { key: 1, isNoc: isNoc, telemetry: port1, pricingModel: pricingModel, unavailableReason: unavailableReason, bothPortsInUse: bothPortsInUse, chargerOcppId: ocppId, portId: 1, allowRemoteAuthorization: allowRemoteAuthorization, chargerFriendlyId: id })),
                port2 != null && (React.createElement(PortInformation, { key: 2, isNoc: isNoc, telemetry: port2, pricingModel: pricingModel, unavailableReason: unavailableReason, bothPortsInUse: bothPortsInUse, chargerOcppId: ocppId, portId: 2, allowRemoteAuthorization: allowRemoteAuthorization, chargerFriendlyId: id }))))));
};
